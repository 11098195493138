import { createSlice } from "@reduxjs/toolkit";
import {
  getBattleData,
  getArenaData,
  betCreate
} from "redux/Actions/Battles";

const BattlesSlice = createSlice({
  name: "authentication",
  initialState: {
    isLoading: false,
    battleData: {},
    arenaData: false,
    betInfo: false,
    error: {},
  },
  reducers: {
    setInitialState: (state) => {
      state.betInfo = false;
      state.error = {};
    },
    setSocketEvent: (state, action) => {
      state.battleData = action.payload.battle;
      state.arenaData = action.payload.arena;
    },
  },
  extraReducers: (builder) => {
    /* Battle */
    builder.addCase(getBattleData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.battleData = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getBattleData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getBattleData.rejected, (state, action) => {
      state.isLoading = false;
      state.battleData = false;
    });

    /* Arena */
    builder.addCase(getArenaData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.arenaData = action.payload.status === 200 ? action.payload.data : false;
    });
    builder.addCase(getArenaData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getArenaData.rejected, (state, action) => {
      state.isLoading = false;
      state.arenaData = false;
    });


    /* Arena */
    builder.addCase(betCreate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.betInfo = action.payload.status === 200 ? action.payload.data : false;
      state.error = action.payload.status !== 200 ? action.payload.data : {};
    });
    builder.addCase(betCreate.pending, (state, action) => {
      state.isLoading = true;
      state.error = {};
    });
    builder.addCase(betCreate.rejected, (state, action) => {
      console.log('here', action.payload);
      state.isLoading = false;
      state.betInfo = false;
      state.error = {};
    });


  }
});

export const { setInitialState, setSocketEvent } = BattlesSlice.actions;
export default BattlesSlice.reducer