import React, { useEffect, useState } from "react";
import LoginImage from "images/Login.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUserBetHistoryData } from "redux/Actions/UserBetHistory";
import Pagination from "components/Pagination";

const BetHistory = () => {

  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.loggedin);
  const { userBetHistoryData } = useSelector(state => state.userBetHistoryData);
  // console.log('userBetHistoryData', userBetHistoryData);

  const [userid, setUserId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (Object.keys(userBetHistoryData).length > 0
      && Object.keys(userBetHistoryData.pagination).length
      && userBetHistoryData.pagination.current_page === 1
    ) {
      setTotal(userBetHistoryData.pagination.total);
      setPerPage(userBetHistoryData.pagination.per_page);
    }
  }, [userBetHistoryData]);
  // console.log('userBetHistoryData',userBetHistoryData)
  useEffect(() => {
    const data = `player_id=${userid}&page=${currentPage}&per_page=${perPage}`;
    dispatch(getUserBetHistoryData(data))
  }, [currentPage]);

  useEffect(() => {
    const data = `player_id=${userid}&page=1&per_page=${perPage}`;
    dispatch(getUserBetHistoryData(data))
  }, [perPage]);

  useEffect(() => {
    const userid = userData.data.user.id;
    setUserId(userid)
    const data = `player_id=${userid}&page=${currentPage}&per_page=12`;
    dispatch(getUserBetHistoryData(data));
  }, [dispatch, userData]);

  return (
    <>
      <section className="dashboard-bg-image" style={{ backgroundImage: `url("${LoginImage}")` }}>
        <div className="container-fluid">
          <div className="">
            <h1 className="transaction-heading text-center">Bet History</h1>
            <div className="transaction_wrapper">
              <div className="transaction_table_wrapper">
                <div className="transaction_table_top_fields">
                  <div className="row">
                    <div className="col-6 col-md-6 col-lg-6">
                      <div className="d-flex">
                        <p className="transaction-table-p">Show</p>
                        <select onChange={(e) => setPerPage(e.target.value)}>
                          <option value={12}>12</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                        </select>
                        <p className="transaction-table-p">Entries</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="transaction_table_fields">
                  <table>
                    <thead>
                      <tr>
                        <th data-cell="S.no.">S.No</th>
                        <th data-cell="Fight Name" data-label="fight Name">Fight Name</th>
                        <th data-cell="Fight Number" data-label="fight Number">Fight Number</th>
                        <th data-cell="Bet" data-label="Bet">Bet</th>
                        <th data-cell="Payout" data-label="Payout">Payout</th>
                        <th data-cell="Status" data-label="Status">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        typeof userBetHistoryData !== 'undefined'
                        && Object.keys(userBetHistoryData).length > 0
                        && Object.keys(userBetHistoryData.data).length
                        && userBetHistoryData.data.map((item, index) => {
                          return (
                            <tr key={`s${index}`}>
                              <td data-cell="S.no." data-label="S.No.">{(currentPage * perPage) + index + 1 - perPage}</td>
                              <td data-cell="Fight Name" data-label="fight Name">{item.battle_name}</td>
                              <td data-cell="Fight Number" data-label="fight Number">{item.fight_number}</td>
                              <td data-cell="Amount" data-label="Amount">{item.amount}</td>
                              <td data-cell="Payout" data-label="Payout">{item.payout}</td>
                              <td data-cell="Status" data-label="Status">{item.status}</td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>

                <div className="transaction-container-bar-next">
                  {(total > perPage) && (
                    <div className="setPagination pb-1 pe-4 pt-3">
                      <Pagination
                        perPage={perPage}
                        total={total}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BetHistory;