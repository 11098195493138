import React, { useEffect, useState } from "react";
import LoginImage from "images/Login.png";
import Murga from "images/Murga.png";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedin } from "redux/Reducers/LoggedSlice";
import { getRegister, getsecretName } from "redux/Actions/Authentication";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import {
  setInitialState,
  setInitialRegister
} from "redux/Reducers/AuthenticationSlice";
import { useParams } from 'react-router-dom';

const SECRET_NAME = process.env.REACT_APP_SECRET_NAME;

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();
  let maxdate = moment().subtract(18, 'y').format('YYYY-MM-DD');
  let { token, id } = useParams();

  const { error, secret } = useSelector(state => state.authentication);
  const { isSuccess, userData } = useSelector(state => state.authentication);
  const { isLoggedin } = useSelector(state => state.loggedin);
  const [act, setACT] = useState(false);
  const [secretName, setSecretName] = useState(SECRET_NAME);

  useEffect(() => {
    if (typeof token !== 'undefined' && typeof id !== 'undefined') {
      dispatch(getsecretName({
        id: id,
        agent_token: token
      }));
    }
  }, [token, id])

  useEffect(() => {
    if (typeof secret !== 'undefined'
      && Object.keys(secret).length > 0
      && typeof secret?.data !== 'undefined'
      && Object.keys(secret?.data).length > 0) {
      setSecretName(secret?.data?.secret_name);
    }
  }, [secret])

  useEffect(() => {
    dispatch(setInitialState());
  }, [])
  // console.log('userData',userData)
  useEffect(() => {
    if (isSuccess || isLoggedin) {
      if (Object.keys(userData).length > 0
        && Object.keys(userData.data).length > 0
        && Object.keys(userData.data.user).length > 0
        && Object.keys(userData.data.user).length > 0
        && userData.data.user.status !== 'Activated') {
        setACT(userData.data.user.status);
        setTimeout(() => {
          dispatch(setInitialRegister());
        }, 2000);
        // return false;
      } else {
        dispatch(setIsLoggedin(userData));
        navigate("/user-dashboard");
      }

    }
  }, [isSuccess])

  const sha512 = (str) => {
    return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
      return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
    });
  }

  const [hash, setHash] = useState(false);
  const handleOnchange = (value) => {
    sha512(`${value}:0:${secretName}`).then(x => setHash(x));
  }


  const onSubmit = (data) => {
    data.secret_name = secretName;
    data.hash = hash;
    const loginapi = {
      foreign_id: data.username,
      secret_name: secretName,
      wallet: 0,
      role_id: 3,
      hashed: hash
    }
    const final = {
      register: data,
      loginapi: loginapi
    }
    dispatch(getRegister(final));
  }

  return (
    <>
      <section className="background-login registerasfadsfform" id="register-form-pg" style={{ backgroundImage: `url("${LoginImage}")` }}>
        <div className="register_wrapper">
          <div className="logo_wrapper">
            <div className="logo_image"><img src={Murga} className="logo" alt="logo" /> </div>
          </div>
          <div className="register_wrapper_form">
            <form onSubmit={handleSubmit(onSubmit)} className="register-form" >
              {act &&
                <span className="error d-block"> Your have successfully registered.
                  your account is approved by agent verry soon. </span>
              }
              <div className={`input-container align-item-center`}>
                <span className="register_form_icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" color="#3498db" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                  </svg>
                </span>
                <input type="text" placeholder="Full Name"
                  {...register("name", {
                    onChange: () => { dispatch(setInitialState()); },
                    required: "Full name should not be empty",
                  })} />
              </div>
              {errors.name && <span className="error d-block">{errors.name.message}</span>}


              <div className={`input-container align-item-center`}>
                <span className="register_form_icon"><svg xmlns="http://www.w3.org/2000/svg" width="18" color="#3498db" height="18" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z" />
                  <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                </svg></span>
                <input type="text" placeholder="Email Address"
                  {...register("email", {
                    onChange: () => { dispatch(setInitialState()); },
                    required: "Email should not be empty",
                  })} />
              </div>
              {errors.email ? <span className="error d-block">{errors.email.message}</span>
                : error && Object.keys(error).length > 0 && Object.keys(error.data).length > 0
                && error.data.email !== "undefined" && <span className="error d-block" >{error.data.email}</span>
              }

              <div className={`input-container align-item-center`}>
                <span className="register_form_icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" color="#3498db" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                  </svg></span>
                <input type="text" placeholder="Phone Number"
                  {...register("phnumber", {
                    onChange: () => { dispatch(setInitialState()); },
                    required: "Phone number should not be empty",
                  })} />
              </div>
              {errors.phnumber ? <span className="error d-block">{errors.phnumber.message}</span>
                : error && Object.keys(error).length > 0
                && Object.keys(error.data).length > 0 && error.data.phnumber !== "undefined" &&
                <span className="error d-block" >{error.data.phnumber}</span>}

              <div className={`input-container align-item-center`}>
                <span className="register_form_icon"> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" color="#3498db" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                </svg></span>
                <input type="text" placeholder="User Name"
                  {...register("username", {
                    onChange: (e) => { handleOnchange(e.target.value); dispatch(setInitialState()); },
                    required: "User name should not be empty",
                  })} />
              </div>

              {errors.username ? <span className="error d-block">{errors.username.message}</span>
                : error && Object.keys(error).length > 0 && Object.keys(error.data).length > 0
                && error.data.username !== "undefined" && <span className="error d-block" >{error.data.username}</span>
              }


              <div className={`input-container align-item-center`}>
                <span className="register_form_icon"><svg placeholder="Date Of Birth"
                  color="#3498db"
                  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar" viewBox="0 0 16 16">
                  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
                </svg></span>
                <input type='date' placeholder="Date of birth"
                  max={maxdate} className="register_date"
                  {...register("date_of_birth", {
                    onChange: () => { dispatch(setInitialState()); },
                    required: "Date of birth name should not be empty",
                  })} />
              </div>
              {errors.date_of_birth && <span className="error d-block">{errors.date_of_birth.message}</span>}

              <div className={`input-container align-item-center`}>
                <span className="register_form_icon"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" color="#3498db" viewBox="0 0 16 16">
                  <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </svg></span>
                <input type="password" placeholder="Password"
                  {...register("password", {
                    onChange: () => { dispatch(setInitialState()); },
                    required: "Password name should not be empty",
                  })} />
              </div>
              {errors.password && <span className="error d-block">{errors.password.message}</span>}

              <div className="text-center">
                <button type="submit" className="pushable1">
                  <span className="front1">
                    REGISTER
                  </span>
                </button>
                <p className="form-bottom-text">Already have an account? | <Link to={"/"} > Sign in </Link></p>
              </div>
            </form>
          </div>
        </div>
      </section>

    </>
  )
}

export default Register;