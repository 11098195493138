import React from "react";
import LoginImage from "images/Login.png";
import UserImage from "images/profile-avatar.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Userprofile = () => {

  const { userProfileData } = useSelector(state => state.userProfileData);

  return (
    <>
      <section className="dashboard-bg-image userinformatipon_dadasd" style={{ backgroundImage: `url("${LoginImage}")` }}>
        <div className="user_profile">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="userprofile_wrapperdasds">
                  <h1 className="userinfo-heading text-center">User Information</h1>
                  <div className="userinfo_wrapper ">
                    <div className="profile-avatar">
                      <img src={UserImage} className="img-fluid" alt="avatar" />
                    </div>
                    <div className="outer_profile_container">
                      <div className="userinfo-blue-bg">
                        <h4 className="userinfo-name-text">
                          {
                            Object.keys(userProfileData).length > 0 &&
                            Object.keys(userProfileData.data).length > 0 &&
                            userProfileData.data.full_name
                          }
                        </h4>
                        <p className="userinfo-prize-text">
                          {
                            Object.keys(userProfileData).length > 0 &&
                            Object.keys(userProfileData.data).length > 0 &&
                            userProfileData.data.wallet
                          }
                        </p>
                        <div className="under-userinfo-blue-bg p-2">
                          <div className="row">
                            <div className="col-6 col-md-6">
                              <ul className="user-details-li">
                                <li>Email</li>
                                <li>Last IP</li>
                                <li>Last Time</li>
                                <li>Regs. Date</li>
                                <li>Regs. Address</li>
                                <li>Currency</li>
                              </ul>
                            </div>
                            <div className="col-6 col-md-6">
                              <ul className="user-details-li">
                                <li>
                                  {
                                    Object.keys(userProfileData).length > 0 &&
                                    Object.keys(userProfileData.data).length > 0 &&
                                    userProfileData.data.email
                                  }
                                </li>
                                <li>122.173.3.193</li>
                                <li>
                                  {
                                    Object.keys(userProfileData).length > 0 &&
                                    Object.keys(userProfileData.data).length > 0 &&
                                    userProfileData.data.updated_at
                                  }
                                </li>
                                <li>
                                  {
                                    Object.keys(userProfileData).length > 0 &&
                                    Object.keys(userProfileData.data).length > 0 &&
                                    userProfileData.data.created_at
                                  }
                                </li>
                                <li>Indian Rupees</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Link to={"/arena"} >
                      <div className="back_to_home1">
                        <button className="back_to_home">
                          <span className="back_to_home_front"> Back To Home </span>
                        </button>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Userprofile;