import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "lib/api";


const getBattleList = createAsyncThunk(
  'admin/battles',
  async (data) => {
    try {
      const response = await api.get(`/battles?page=${data.page}&per_page=${data.per_page}${data.QST}`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const getCsvBattle = createAsyncThunk(
  'admin/csv/battles',
  async (data) => {
    try {
      const response = await api.get(`/battles?page=${data.page}&per_page=${data.per_page}${data.QST}`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const getBattleDetails = createAsyncThunk(
  'admin/battle/{your-battle-id} ',
  async (data) => {
    try {
      const response = await api.get(`/battle/${data} `, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

export {
  getBattleList,
  getCsvBattle,
  getBattleDetails
};