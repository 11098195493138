import { createSlice } from "@reduxjs/toolkit";
import { getUserProfileData } from "redux/Actions/Userporfile";

const UserprofileSlice = createSlice({
    name: "userProfileData",
    initialState: {
        isLoading: false,
        userProfileData: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        /* User Details */
        builder.addCase(getUserProfileData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userProfileData = action.payload.status === 200 ? action.payload.data : false;
        });
        builder.addCase(getUserProfileData.pending, (state, action) => {
            state.isLoading = true;
            state.userProfileData = false;
        });
        builder.addCase(getUserProfileData.rejected, (state, action) => {
            state.isLoading = false;
            state.userProfileData = false;
        });

    }
});

export default UserprofileSlice.reducer