import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getBattleDetails } from "redux/Actions/Admin/Battles";
import { Link } from "react-router-dom";

const SingleBattel = () => {
  const { id } = useParams();
  const { battleDetails } = useSelector(state => state.adminBattle);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBattleDetails(id));
  }, [id]);
  
  return (
    <>
      <main className="active-bg" id="bet-histories">
        <div className="container-fluid">
          <div className="row">

            <div className="backtohistory">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-6">
                      <Link to='/admin/bettels' className="text-white apply_filter_button"> Back to Histories </Link>
                    <div className="gap-4 mt-3">
                      <p className="text-white">Fight No. <span className="highlight-color">
                        {battleDetails?.data?.fight_number} </span> | Battle ID. <span className="highlight-color">{battleDetails?.data?.id} </span> | Battle Code. <span className="highlight-color">{battleDetails?.data?.name}</span></p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="details-text-end">
                      <p className="text-white">Status: <span className="highlight-color"> {battleDetails?.data?.status}</span></p>
                      <p className="text-white">Closed At: <span className="highlight-color">{battleDetails?.data?.closed_at}</span></p>
                      <p className="text-white">Date: <span className="highlight-color">{battleDetails?.data?.created_at}</span></p>
                    </div>
                  </div>
                </div>

                <hr className="hr-line" />

                <div className="row">
                  <div className="col-12">
                    <div className="backtohistory-icon-box">
                      <div className="row">
                        <div className="col-12 m-0">

                          <div className="row">
                            <div className="col-6">
                              <p className="text-light text-start">Meron Bet</p>
                            </div>
                            <div className="col-6">
                              <p className="text-light text-end">
                                ₹{parseFloat(battleDetails?.data?.m_bet).toFixed(2)}</p>
                            </div>

                            <div className="col-6">
                              <p className="text-light text-start">Wala Bet</p>
                            </div>
                            <div className="col-6">
                              <p className="text-light text-end">
                                ₹{parseFloat(battleDetails?.data?.w_bet).toFixed(2)}</p>
                            </div>
                            <hr className="hr-line" />

                            <div className="col-6">
                              <p className="text-light text-start">Total Bet</p>
                            </div>
                            <div className="col-6">
                              <p className="text-light text-end">
                                ₹{parseFloat(battleDetails?.data?.total_bet).toFixed(2)}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="col-12 col-md-12 col-lg-12">
                <div className="row">
                  <div className="col-12">
                    <div className="backtohistory-icon-box">
                      <div className="row">
                        <div className="col-12 m-0">

                          <div className="row">
                            <div className="col-6">
                              <p className="text-light text-start">Payout</p>
                            </div>
                            <div className="col-6">
                              <p className="text-light text-end">
                                ₹{parseFloat(battleDetails?.data?.payout).toFixed(2)}</p>
                            </div>

                            <div className="col-6">
                              <p className="text-light text-start">Net Pay</p>
                            </div>
                            <div className="col-6">
                              <p className="text-light text-end">
                                ₹{parseFloat(battleDetails?.data?.net_pay).toFixed(2)}</p>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="col-12 col-md-12 col-lg-12">
              <div className="row">
                <div className="col-12">
                  <div className="backtohistory-icon-box">
                    <div className="row">
                      <div className="col-12 m-0">

                        <div className="row">
                          <div className="col-6">
                            <p className="text-light text-start">Total</p>
                          </div>
                          <div className="col-6">
                            <p className="text-light text-end">
                              ₹{parseFloat(battleDetails?.data?.total_commission).toFixed(2)}</p>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="table-wrapper">

            <div className="table-container">
              <h1 className="active-caption">Bets</h1><table>


                <tbody>

                  <tr>
                    <th>USER ID</th>
                    <th>USERNAME</th>
                    <th>AVAILABLE WALLET</th>
                    <th>TO</th>
                    <th>BET AMOUNT</th>
                    <th>OPERATOR</th>
                    <th>Agent Commission</th>
                    <th>PAYOUT</th>
                    <th>ACTION</th>
                  </tr>

                  <tr>
                    <td data-cell="USER ID" data-label="USER ID">01</td>
                    <td data-cell="USERNAME" data-label="USERNAME">nick-1</td>
                    <td data-cell="AVAILABLE WALLET" data-label="AVAILABLE WALLET">₹10</td>
                    <td data-cell="IS DUMMY" data-label="IS DUMMY">₹0</td>
                    <td data-cell="TO" data-label="TO">Meron</td>
                    <td data-cell="BET AMOUNT" data-label="BET AMOUNT"> ₹10 </td>
                    <td data-cell="OPERATOR" data-label="OPERATOR">₹10</td>
                    <td data-cell="PLATINUM" data-label="PLATINUM">₹10</td>
                    <td data-cell="ACTION" data-label="ACTION"><span><button href="" className="action-btn-history">View Sponsors</button></span></td>
                  </tr>
                  <tr>
                    <td data-cell="USER ID" data-label="USER ID">01</td>
                    <td data-cell="USERNAME" data-label="USERNAME">nick-1</td>
                    <td data-cell="AVAILABLE WALLET" data-label="AVAILABLE WALLET">₹10</td>
                    <td data-cell="IS DUMMY" data-label="IS DUMMY">₹0</td>
                    <td data-cell="TO" data-label="TO">Meron</td>
                    <td data-cell="BET AMOUNT" data-label="BET AMOUNT"> ₹10 </td>
                    <td data-cell="OPERATOR" data-label="OPERATOR">₹10</td>
                    <td data-cell="PLATINUM" data-label="PLATINUM">₹10</td>
                    <td data-cell="ACTION" data-label="ACTION"><span><button href="" className="action-btn-history">View Sponsors</button></span></td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>

      </main>
    </>
  )
}

export default SingleBattel;