import { createSlice } from "@reduxjs/toolkit";
import {
  getBattleList,
  getCsvBattle,
  getBattleDetails
} from "redux/Actions/Admin/Battles";

const BattleSlice = createSlice({
  name: "BattleSlice",
  initialState: {
    isLoading: false,
    isSuccess: false,
    battleList: {},
    battleDetails: {},
    csvBattle: {},
    error: {},
  },
  reducers: {
    setInitialState: (state) => {
      state.isSuccess = false;
      state.csvBattle = {};
    }
  },
  extraReducers: (builder) => {

    /* Battles List */
    builder.addCase(getBattleList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.battleList = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getBattleList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBattleList.rejected, (state) => {
      state.isLoading = false;
      state.battleList = {};
    });

    /* Battles csv List */
    builder.addCase(getCsvBattle.fulfilled, (state, action) => {
      state.isLoading = false;
      state.csvBattle = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getCsvBattle.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCsvBattle.rejected, (state) => {
      state.isLoading = false;
      state.csvBattle = {};
    });
   
    /* Battles csv List */
    builder.addCase(getBattleDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.battleDetails = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getBattleDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBattleDetails.rejected, (state) => {
      state.isLoading = false;
    });

  }
});

export const {
  setInitialState
} = BattleSlice.actions

export default BattleSlice.reducer