import { createSlice } from "@reduxjs/toolkit";
import { getUserGaneHistoryData } from "redux/Actions/UserGameHistory";

const userBetSlice = createSlice({
    name: "getUserGaneHistoryData",
    initialState: {
        isLoading: false,
        userGameHistoryData: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        /* User Details */
        builder.addCase(getUserGaneHistoryData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userGameHistoryData = action.payload.status === 200 ? action.payload.data : false;
        });
        builder.addCase(getUserGaneHistoryData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getUserGaneHistoryData.rejected, (state, action) => {
            state.isLoading = false;
            state.userGameHistoryData = false;
        });

    }
});

export default userBetSlice.reducer