import React, { useState, useEffect } from "react";
import Murga from "images/Murga.png";
import ruppeicon from "images/rupeeicon.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBillTransfer,
  faRightFromBracket,
  faHandshake,
  faHome
} from '@fortawesome/free-solid-svg-icons';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { setLogout } from "redux/Reducers/LoggedSlice";
import { useSelector } from "react-redux";
// import { getBattleData } from "redux/Actions/Battles";
import { setWallet } from "redux/Reducers/LoggedSlice"
import { io } from "socket.io-client";
import { getUserProfileData } from "redux/Actions/Userporfile";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const SOCKET_KEY = process.env.REACT_APP_SOCKET_KEY;

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Logout = () => {
    dispatch(setLogout());
    navigate("/");
  }

  const { userData, wallet } = useSelector(state => state.loggedin);

  const [burger_class, setBurgerClass] = useState("bar")
  const [menu_class, setMenuClass] = useState("nav-menu-hidden")
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [backgroundContainer, setBackgroundContainer] = useState('clickNoneContainer')
  const { userProfileData } = useSelector(state => state.userProfileData);
  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass("cross");
      setMenuClass("nav-menu-visible");
      setIsMenuClicked(true);
      setBackgroundContainer("clickDisplayContainer");
    }
    else {
      setBurgerClass("bar");
      setMenuClass("nav-menu-hidden");
      setIsMenuClicked(false);
      setBackgroundContainer("clickNoneContainer");
    }
  }

  const [socketUUID, setSocketUUID] = useState(0);
  useEffect(() => {
    if (Object.keys(userData).length > 0
      && Object.keys(userData.data).length > 0
      && Object.keys(userData.data.gatekeeper).length > 0
    ) {
      setSocketUUID(userData.data.gatekeeper.uuid);
    }
    const username = userData.data.user.username;
    dispatch(getUserProfileData(username));    
    
  }, [dispatch, userData]);
  

  useEffect(() => {
    if (Object.keys(userProfileData).length > 0
      && Object.keys(userProfileData.data).length > 0
    ) {      
      dispatch(setWallet(parseFloat(userProfileData.data.wallet).toFixed(2)));
    }
  }, [userProfileData])
  let options = {
    reconnection: true,
    forceNew: true,
    transports: ['websocket'],
    query: {
      token: localStorage.getItem("token"),
      uuid: socketUUID
    },
    path: SOCKET_KEY
  };

  const [run,setRun] = useState(false);
  useEffect(()=>{
    setInterval(() => {
      setRun(!run);
    }, 2000);
  },[])
  const socket2 = io(SOCKET_URL, options);
  
  useEffect(() => {        
    socket2.on(`${userData?.data?.user?.secret_name}`, (rec) => {
      if (rec?.event === "Bet") {
        dispatch(setWallet(parseFloat(rec.data?.wallet).toFixed(2)));
      }
      if (rec?.from === 'Wallet') {
        dispatch(setWallet(parseFloat(rec.data).toFixed(2)));
      }
    });
    return () => socket2.disconnect();
  }, [run]);
  


  return (
    <>
      <section className="announcement_bar">
        <marquee text="murga">Your Playing Hours are between 8am to 9pm</marquee>
      </section>
      <div className={menu_class}>

        <Link to="/user-dashboard">
          <FontAwesomeIcon icon={faHome} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Home
          </span>
        </Link>
        <Link to="/game-history">
          <FontAwesomeIcon icon={faUserPen} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Games
          </span>
        </Link>
        <Link to="/bet-history">
          <FontAwesomeIcon icon={faHandshake} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Bets
          </span>
        </Link>
        <Link to="/user-transaction" className="transaction-div" >
          <FontAwesomeIcon icon={faMoneyBillTransfer} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Transactions
          </span>
        </Link>

        <Link onClick={() => Logout()} className="logout-container" >
          <FontAwesomeIcon icon={faRightFromBracket} />
        </Link>

      </div>
      <div className="sidebar">

        <div className="murga-logo">
          <Link to="/user-dashboard"><img src={Murga} className="img-fluid sidebar-logo" alt="logo" />
          </Link>
        </div>

        <Link to="/user-transaction" id="murgafight_wallet" >
          <img src={ruppeicon} width="100%" />
          <span>
            {wallet && parseFloat(wallet).toFixed(2)}
          </span>
        </Link>

        <div className="hamburger" onClick={updateMenu}>
          <span className={burger_class}></span>
          <span className={burger_class}></span>
          <span className={burger_class}></span>
        </div>
        <div className={backgroundContainer} onClick={updateMenu} ></div>

      </div>
    </>
  )
}

export default Header;