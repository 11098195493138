import { createSlice } from "@reduxjs/toolkit";
import {
  getDashboard
} from "redux/Actions/Admin/Dashboard";

const DashboardSlice = createSlice({
  name: "DashboardSlice",
  initialState: {
    isLoading: false,
    isSuccess: false,
    dashboardData: {},
  },
  reducers: {
    setInitialState: (state) => {
      state.isSuccess = false;
    }
  },
  extraReducers: (builder) => {

    /* Get Dashboard Data */
    builder.addCase(getDashboard.fulfilled, (state, action) => {
      state.isLoading = false;
      state.dashboardData = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getDashboard.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getDashboard.rejected, (state) => {
      state.isLoading = false;
      state.dashboardData = {};
    });

  }
});

export const {
  setInitialState
} = DashboardSlice.actions

export default DashboardSlice.reducer