import { createSlice } from "@reduxjs/toolkit";
import {
  getUserUpdate,
  getUserList,
  getCsvUser,
  getUserDetails,
  getUserMurgaDetails,
  agentRegister
} from "redux/Actions/Admin/User";

const UserdSlice = createSlice({
  name: "UserdSlice",
  initialState: {
    isLoading: false,
    isSuccess: false,
    userList: {},
    register: {},
    userMurga: {},
    userDetails: {},
    csvUser: {},
    error: {},
  },
  reducers: {
    setInitialState: (state) => {
      state.isSuccess = false;
      state.csvUser = {};
    }
  },
  extraReducers: (builder) => {

    /* User list */
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userList = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getUserList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserList.rejected, (state) => {
      state.isLoading = false;
      state.userList = {};
    });

    /* User csv list */
    builder.addCase(getCsvUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.csvUser = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getCsvUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCsvUser.rejected, (state) => {
      state.isLoading = false;
      state.csvUser = {};
    });

    /* User csv list */
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userDetails = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getUserDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserDetails.rejected, (state) => {
      state.isLoading = false;
    });

    /* User Register  */
    builder.addCase(agentRegister.fulfilled, (state, action) => {
      // console.log('action.payload',action.payload)
      state.isLoading = false;
      state.isSuccess = action.payload?.status === 200 ? true : false;
      state.error = action.payload?.status !== 200 ? action.payload?.data : false;
    });
    builder.addCase(agentRegister.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(agentRegister.rejected, (state) => {
      state.isLoading = false;
    });

    /* User murga details */
    builder.addCase(getUserMurgaDetails.fulfilled, (state, action) => {      
      state.isLoading = false;
      state.userMurga = action.payload?.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getUserMurgaDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserMurgaDetails.rejected, (state) => {
      state.isLoading = false;
    });

    /* User update */
    builder.addCase(getUserUpdate.fulfilled, (state, action) => {      
      state.isLoading = false;
      state.isSuccess = action.payload?.status === 200 ? true : false;
      state.error = action.payload?.status !== 200 ? action.payload?.data : false;
    });
    builder.addCase(getUserUpdate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUserUpdate.rejected, (state) => {
      state.isLoading = false;
    });

  }
});

export const {
  setInitialState
} = UserdSlice.actions

export default UserdSlice.reducer