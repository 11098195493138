import { createSlice } from "@reduxjs/toolkit";
import {
  getBetList,
  getCsvBet
} from "redux/Actions/Admin/Bets";

const BetsSlice = createSlice({
  name: "BetsSlice",
  initialState: {
    isLoading: false,
    isSuccess: false,
    betList: {},
    csvBet: {},
    error: {},
  },
  reducers: {
    setInitialState: (state) => {
      state.csvBet = {};
      state.isSuccess = false;
    }
  },
  extraReducers: (builder) => {

    /* Bet list */
    builder.addCase(getBetList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.betList = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getBetList.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBetList.rejected, (state) => {
      state.isLoading = false;
      state.betList = {};
    });

    /* Bet csv list */
    builder.addCase(getCsvBet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.csvBet = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getCsvBet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCsvBet.rejected, (state) => {
      state.isLoading = false;
      state.csvBet = {};
    });

  }
});

export const {
  setInitialState
} = BetsSlice.actions

export default BetsSlice.reducer