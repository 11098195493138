import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "lib/api";

const getBattleData = createAsyncThunk(
  'battle/arenaId',
  async (arenaid) => {
    try {
      const response = await api.get(`battle/${arenaid}?to=arena`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const getArenaData = createAsyncThunk(
  'battle/arena',
  async (arenaid) => {
    try {
      const response = await api.get(`arena/${arenaid}`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)


const betCreate = createAsyncThunk(
  'bet/create',
  async (data) => {
    try {
      const response = await api.post(`bet/create`, data, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

export {
  getBattleData,
  getArenaData,
  betCreate
};