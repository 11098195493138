import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserDetails,
  getUserMurgaDetails,
  getUserUpdate
} from "redux/Actions/Admin/User";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { useParams } from "react-router-dom";
import { setInitialState } from "redux/Reducers/Admin/UserSlice";
import { useForm } from "react-hook-form";

const UserDetails = () => {
  const { id } = useParams();
  const { userDetails } = useSelector(state => state.adminUser);

  const { register, handleSubmit, setValue, formState: { errors } } = useForm();
  const { isSuccess, userMurga, error } = useSelector(state => state.adminUser);
  
  // const { agentListMurga } = useSelector(state => state.AgentUserListData);
  const dispatch = useDispatch();
  const [curUser, setCurUser] = useState(false);
  useEffect(() => {
    dispatch(getUserDetails(id));
    const useName = id.replace('-3', '');
    dispatch(getUserMurgaDetails({ username: useName }));
  }, [id]);
  useEffect(() => {
    if (Object.keys(userMurga).length > 0
      && Object.keys(userMurga.data).length > 0
    ) {
      const useName = id.replace('-3', '');
      const user = userMurga.data.find(item => item.username === useName);
      setCurUser(user);
      setValue('username', userMurga?.data[0].username);
      setValue('email', userMurga?.data[0].email);
      setValue('phnumber', userMurga?.data[0].phnumber);
    }
  }, [userMurga])
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(setInitialState());
      }, 5000);
    }
  }, [isSuccess])

  const onSubmit = (data) => {
    data.id = curUser.id;
    console.log('data', data);
    dispatch(getUserUpdate(data));
  }
  return (
    <>
      <ToastContainer transition={Zoom} position={toast.POSITION.TOP_CENTER} />
      <main className="active-bg" id="agent_dashboard">
        <div className="container" bis_skin_checked="1">
          <h1 className="active-caption">Edit User Account</h1>
          <div className="ad-cards" bis_skin_checked="1">
            <div className="row" bis_skin_checked="1">
              <div className="col-12 col-md-6 col-lg-6" bis_skin_checked="1">
                <div className="ad-dash-bottom-icon-box" bis_skin_checked="1">
                  <div className="row" bis_skin_checked="1">
                    <div className="col-12 text-center" bis_skin_checked="1">
                      <h5 className="text-light">Cash In</h5>
                      <h1 className="text-light">
                        ₹{userDetails?.data?.cash_in && parseFloat(userDetails?.data?.cash_in).toFixed(2)}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" bis_skin_checked="1">
                <div className="ad-dash-bottom-icon-box" bis_skin_checked="1">
                  <div className="row" bis_skin_checked="1">
                    <div className="col-12 text-center" bis_skin_checked="1">
                      <h5 className="text-light">Cash Out</h5>
                      <h1 className="text-light">
                        ₹{userDetails?.data?.cash_out && parseFloat(userDetails?.data?.cash_out).toFixed(2)}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" bis_skin_checked="1">
                <div className="ad-dash-bottom-icon-box" bis_skin_checked="1">
                  <div className="row" bis_skin_checked="1">
                    <div className="col-12 text-center" bis_skin_checked="1">
                      <h5 className="text-light">Battle Histories</h5>
                      <h1 className="text-light">₹0</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6" bis_skin_checked="1">
                <div className="ad-dash-bottom-icon-box" bis_skin_checked="1">
                  <div className="row" bis_skin_checked="1">
                    <div className="col-12 text-center" bis_skin_checked="1">
                      <h5 className="text-light">Bet Histories</h5>
                      <h1 className="text-light">₹0</h1>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-12 col-lg-12" bis_skin_checked="1">
                <div className="row" bis_skin_checked="1">
                  <div className="col-12" bis_skin_checked="1">
                    <div className="ad-dash-bottom-icon-box" bis_skin_checked="1">
                      <div className="row" bis_skin_checked="1">
                        <div className="col-12 text-center" bis_skin_checked="1">
                          <h5 className="text-light">Wallet Balance</h5>
                          <h1 className="text-light">₹{parseFloat(userDetails?.data?.wallet).toFixed(2)}</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="col-span-12 user_acc_bg" bis_skin_checked="1">
            {isSuccess &&
              <span className="success d-block">
                Update successfully
              </span>
            }
            <form onSubmit={handleSubmit(onSubmit)} className="register-form" >
              <div className="col-span-12 user_acc_bg">
                <div className="mb-3">
                  <label className="text-form-label font-semibold" htmlFor="username">Username</label>
                  <input placeholder="username" type="text" readOnly
                    className="block w-full px-2 py-2 mt-2 placeholder-gray-400 bg-gray-200"
                    {...register("username", {
                      required: 'Username should not be empty'
                    })} />
                  {errors.username ? <span className="error d-block">{errors.username.message}</span>
                    : error && Object.keys(error).length > 0
                    && typeof error?.data !== 'undefined'
                    && Object.keys(error.data).length > 0
                    && error?.data?.username !== "undefined" && <span className="error d-block" >{error?.data?.username}</span>
                  }
                </div>
                <div className="mb-3">
                  <label className="text-form-label font-semibold" htmlFor="email">Email</label>
                  <input placeholder="Email" type="email"
                    className="block w-full px-2 py-2 mt-2 placeholder-gray-400"
                    {...register("email", {
                      required: 'Email should not be empty'
                    })} />
                  {errors.email ? <span className="error d-block">{errors.email.message}</span>
                    : error && Object.keys(error).length > 0
                    && typeof error?.data !== 'undefined'
                    && Object.keys(error.data).length > 0
                    && error?.data?.email !== "undefined" && <span className="error d-block" >{error?.data?.email}</span>
                  }
                </div>
                <div className="mb-3">
                  <label className="text-form-label font-semibold" htmlFor="phone_number">Phone Number</label>
                  <input placeholder="Phone Number" type="number"
                    className="block w-full px-2 py-2 mt-2 placeholder-gray-400r"
                    {...register("phnumber", {
                      required: 'Phone number should not be empty'
                    })} />
                  {errors.phnumber ? <span className="error d-block">{errors.phnumber.message}</span>
                    : error && Object.keys(error).length > 0
                    && typeof error?.data !== 'undefined'
                    && Object.keys(error.data).length > 0 && error?.data?.phnumber !== "undefined" &&
                    <span className="error d-block" >{error?.data?.phnumber}</span>}
                </div>


                <div className="mb-3 flex gap-4">
                  <button className="flex-1 w-full border font-semibold text-gray-800 py-2 mt-2 user_acc-form-btn-submit" type="submit"> Update </button>
                </div>
              </div>
            </form>
          </div>

        </div>

        <div className="container-fluid" bis_skin_checked="1">
          <div className="col-12 col-md-12 col-lg-12" bis_skin_checked="1">
            <div className="table-wrapper" bis_skin_checked="1">

              <div className="table-container" bis_skin_checked="1">
                <h1 className="active-caption">Bets</h1><table>


                  <tbody>

                    <tr>
                      <th>USER ID</th>
                      <th>USERNAME</th>
                      <th>AVAILABLE WALLET</th>
                      <th>IS DUMMY</th>
                      <th>TO</th>
                      <th>BET AMOUNT</th>
                      <th>OPERATOR</th>
                      <th>PLATINUM</th>
                      <th>MASTER</th>
                      <th>GOLD</th>
                      <th>PAYOUT</th>
                      <th>NET PAY</th>
                      <th>ACTION</th>
                    </tr>

                    <tr>
                      <td data-cell="USER ID">01</td>
                      <td data-cell="USERNAME">nick-1</td>
                      <td data-cell="AVAILABLE WALLET">₹10</td>
                      <td data-cell="IS DUMMY">₹0</td>
                      <td data-cell="TO">Meron</td>
                      <td data-cell="BET AMOUNT"> ₹10 </td>
                      <td data-cell="OPERATOR">₹10</td>
                      <td data-cell="PLATINUM">₹10</td>
                      <td data-cell="MASTER">₹10</td>
                      <td data-cell="GOLD">₹10</td>
                      <td data-cell="PAYOUT">₹10</td>
                      <td data-cell="NET PAY">₹10</td>
                      <td data-cell="ACTION"><span><button href="" className="action-btn-history" fdprocessedid="ihbjb">View Sponsors</button></span></td>
                    </tr>
                    <tr>
                      <td data-cell="USER ID">01</td>
                      <td data-cell="USERNAME">nick-1</td>
                      <td data-cell="AVAILABLE WALLET">₹10</td>
                      <td data-cell="IS DUMMY">₹0</td>
                      <td data-cell="TO">Meron</td>
                      <td data-cell="BET AMOUNT"> ₹10 </td>
                      <td data-cell="OPERATOR">₹10</td>
                      <td data-cell="PLATINUM">₹10</td>
                      <td data-cell="MASTER">₹10</td>
                      <td data-cell="GOLD">₹10</td>
                      <td data-cell="PAYOUT">₹10</td>
                      <td data-cell="NET PAY">₹10</td>
                      <td data-cell="ACTION"><span><button href="" className="action-btn-history" fdprocessedid="ts7kvi">View Sponsors</button></span></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div bis_skin_checked="1">
            <div className="table-wrapper" bis_skin_checked="1">

              <div className="table-container" bis_skin_checked="1">
                <h1 className="active-caption">Bettles</h1><table>


                  <tbody>

                    <tr>
                      <th>USER ID</th>
                      <th>USERNAME</th>
                      <th>AVAILABLE WALLET</th>
                      <th>IS DUMMY</th>
                      <th>TO</th>
                      <th>BET AMOUNT</th>
                      <th>OPERATOR</th>
                      <th>PLATINUM</th>
                      <th>MASTER</th>
                      <th>GOLD</th>
                      <th>PAYOUT</th>
                      <th>NET PAY</th>
                      <th>ACTION</th>
                    </tr>

                    <tr>
                      <td data-cell="USER ID">01</td>
                      <td data-cell="USERNAME">nick-1</td>
                      <td data-cell="AVAILABLE WALLET">₹10</td>
                      <td data-cell="IS DUMMY">₹0</td>
                      <td data-cell="TO">Meron</td>
                      <td data-cell="BET AMOUNT"> ₹10 </td>
                      <td data-cell="OPERATOR">₹10</td>
                      <td data-cell="PLATINUM">₹10</td>
                      <td data-cell="MASTER">₹10</td>
                      <td data-cell="GOLD">₹10</td>
                      <td data-cell="PAYOUT">₹10</td>
                      <td data-cell="NET PAY">₹10</td>
                      <td data-cell="ACTION"><span><button href="" className="action-btn-history" fdprocessedid="2z7uwf">View Sponsors</button></span></td>
                    </tr>
                    <tr>
                      <td data-cell="USER ID">01</td>
                      <td data-cell="USERNAME">nick-1</td>
                      <td data-cell="AVAILABLE WALLET">₹10</td>
                      <td data-cell="IS DUMMY">₹0</td>
                      <td data-cell="TO">Meron</td>
                      <td data-cell="BET AMOUNT"> ₹10 </td>
                      <td data-cell="OPERATOR">₹10</td>
                      <td data-cell="PLATINUM">₹10</td>
                      <td data-cell="MASTER">₹10</td>
                      <td data-cell="GOLD">₹10</td>
                      <td data-cell="PAYOUT">₹10</td>
                      <td data-cell="NET PAY">₹10</td>
                      <td data-cell="ACTION"><span><button href="" className="action-btn-history" fdprocessedid="xcy5le">View Sponsors</button></span></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>


      </main>
    </>
  )
}

export default UserDetails;