import React, { useEffect, useState } from "react";
import CashinOut from "images/cashinout.png";
import comission from "images/comission.png";
import moneypot from "images/moneypot.png";
import onlineactivatedwallet from "images/onlineactivatedwallet.png";
import pendingaccount from "images/pendingaccount.png";
import systemwallet from "images/systemwallet.png";
import useronline from "images/useronline.png";
import { useDispatch, useSelector } from "react-redux";
import {
  agentDashboardData,
  walletTransactions,
  getagentData
} from "redux/Actions/AgentDashboard"
const SITE_URL = process.env.REACT_APP_SITE_URL;

const AgentDashboard = () => {
  // agent-details
  const dispatch = useDispatch();
  const { agentDashboard, agentData } = useSelector(state => state.AgentDashbaordSlice);
  const { userData } = useSelector(state => state.loggedin);
  
  useEffect(() => {
    if (Object.keys(userData).length > 0

      && Object.keys(userData.data).length > 0
      && Object.keys(userData.data.user).length > 0) {
      dispatch(getagentData({
        secret_name: userData.data.user.secret_name
      }));
    }
  }, [userData]);

  const [refrelLink, setRefrelLink] = useState('');

  useEffect(() => {
    if (
      typeof agentData !== 'undefined'
      && Object.keys(agentData).length > 0
      && typeof agentData.data !== 'undefined'
      && Object.keys(agentData.data).length > 0) {
      const link = `${SITE_URL}/register/${agentData.data.agent_token}/${agentData.data.id}`
      setRefrelLink(link)
    }
  }, [agentData]);

  useEffect(() => {
    dispatch(agentDashboardData());
    dispatch(walletTransactions({
      page: 1,
      per_page: 10,
      QST: ''
    }));
  }, []);
  const [copied, setCopied] = useState(false);

  const onClickCopy = () => {
    navigator.clipboard.writeText(refrelLink);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }
  return (
    <>
      <main className="active-bg" id="agent_dashboard">
        <div className="container-fluid">
          <div className="row">
            <h1 className="active-caption">Agent Dashboard</h1>
            <div className="agent-wrapper">
              <div className="ad-cards">
                <div className="row">
                  <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                    <div className="ad-icon-box">
                      <div className="row">
                        <div className="col-4 text-center">
                          <img src={moneypot} className="img-fluid ad-cards-icon" alt="icon" />
                        </div>
                        <div className="col-8">
                          <p className="text-light">Total Money Pot</p>
                          <h5 className="text-light">₹ {parseFloat(agentDashboard?.data?.wallet).toFixed(2)}</h5>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                    <div className="ad-icon-box">
                      <div className="row">
                        <div className="col-4 text-center">
                          <img src={onlineactivatedwallet} className="img-fluid ad-cards-icon" alt="icon" />
                        </div>
                        <div className="col-8">
                          <p className="text-light">Wallet activated account</p>
                          <h5 className="text-light">₹1,000</h5>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                    <div className="ad-icon-box">
                      <div className="row">
                        <div className="col-4 text-center">
                          <img src={comission} className="img-fluid ad-cards-icon" alt="icon" />
                        </div>
                        <div className="col-8">
                          <p className="text-light">Commission Wallet </p>
                          <h5 className="text-light">₹ {parseFloat(agentDashboard?.data?.commission_wallet).toFixed(2)}</h5>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                    <div className="ad-icon-box">
                      <div className="row">
                        <div className="col-4 text-center">
                          <img src={moneypot} className="img-fluid ad-cards-icon" alt="icon" />
                        </div>
                        <div className="col-8">
                          <p className="text-light">Total System Wallet </p>
                          <h5 className="text-light">₹ {parseFloat(agentDashboard?.data?.total_commission).toFixed(2)}</h5>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                    <div className="ad-icon-box">
                      <div className="row">
                        <div className="col-4 text-center">
                          <img src={useronline} className="img-fluid ad-cards-icon" alt="icon" />
                        </div>
                        <div className="col-8">
                          <p className="text-light"> Online Users</p>
                          <h5 className="text-light">{parseFloat(agentDashboard?.data?.user_activated).toFixed(2)}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                    <div className="ad-icon-box">
                      <div className="row">
                        <div className="col-4 text-center">
                          <img src={pendingaccount} className="img-fluid ad-cards-icon" alt="icon" />
                        </div>
                        <div className="col-8">
                          <p className="text-light"> Total Pending Account</p>
                          <h5 className="text-light">{parseFloat(agentDashboard?.data?.user_pending).toFixed(2)}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                    <div className="ad-icon-box">
                      <div className="row">
                        <div className="col-4 text-center">
                          <img src={CashinOut} className="img-fluid ad-cards-icon" alt="icon" />
                        </div>
                        <div className="col-8">
                          <p className="text-light"> Total Cash In</p>
                          <h5 className="text-light">₹ {parseFloat(agentDashboard?.data?.cash_in).toFixed(2)}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                    <div className="ad-icon-box">
                      <div className="row">
                        <div className="col-4 text-center">
                          <img src={CashinOut} className="img-fluid ad-cards-icon" alt="icon" />
                        </div>
                        <div className="col-8">
                          <p className="text-light"> Total Cash Out </p>
                          <h5 className="text-light">₹ {parseFloat(agentDashboard?.data?.cash_out).toFixed(2)}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="agent_blue_bg">
                <h5 className="ad-caption">Referral Link</h5>
                <p className="ad-sub-caption">Please Take note of your refral link bellow</p>
                <hr className="hr-line" />
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-12">
                    <div className="relative w-auto">
                      <div className="copyrefrellink">
                        <input type="text" readOnly value={refrelLink} />
                        {copied && <span style={{ color: "green" }}>copied</span>}
                        <div className="align-item-start">
                          <button className="text-sm text-white ad_button"
                            onClick={() => onClickCopy()}>
                            <ion-icon name="copy-outline" role="img" className="md hydrated"></ion-icon>
                            Copy Referral Link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-12 col-md-6 col-lg-6">
                    <div className="relative w-auto">
                      <div className="copyrefrellink">
                          <textarea readOnly>{ userData.data.gatekeeper.token }</textarea>
                        <div class="align-item-start">
                          <button class="text-sm text-white ad_button" fdprocessedid="t3dvtx"><ion-icon name="copy-outline" role="img" className="md hydrated"></ion-icon> Copy Secret </button>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="ad_all_users">
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="agent_blue_bg">
                      <div className="allusers-spacing">
                        <h5 className="allusers-text">All Users </h5>
                      </div>
                      <hr className="hr-line1" />
                      <div>
                        <div className="row">
                          <div className="col-2 text-center">
                            <img src={pendingaccount} className="img-fluid alluser-img" alt="icon" />
                          </div>
                          <div className="col-7">
                            <p className="allusers-text">Pending User Account</p>
                          </div>
                          <div className="col-3">
                            <h5 className="allusers-text">{agentDashboard?.data?.user_pending}</h5>
                          </div>
                        </div>
                        <hr className="hr-line1" />
                        <div className="row">
                          <div className="col-2 text-center">
                            <img src={useronline} className="img-fluid alluser-img" alt="icon" />
                          </div>
                          <div className="col-7">
                            <p className="allusers-text">Active User Account</p>
                          </div>
                          <div className="col-3">
                            <h5 className="allusers-text">{agentDashboard?.data?.user_activated}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div className="agent_blue_bg">
                      <div className="allusers-spacing">
                        <h5 className="allusers-text">Balance</h5>
                      </div>
                      <hr className="hr-line1" />
                      <div>
                        <div className="row">
                          <div className="col-2 text-center">
                            <img src={systemwallet} className="img-fluid alluser-img" alt="icon" />
                          </div>
                          <div className="col-7">
                            <p className="allusers-text">Wallet Balance</p>
                          </div>
                          <div className="col-3">
                            <h5 className="allusers-text">₹ {parseFloat(agentDashboard?.data?.wallet).toFixed(2)}</h5>
                          </div>
                        </div>
                        <hr className="hr-line1" />
                        <div className="row">
                          <div className="col-2 text-center">
                            <img src={systemwallet} className="img-fluid alluser-img" alt="icon" />
                          </div>
                          <div className="col-7">
                            <p className="allusers-text">Comission Wallet Balance</p>
                          </div>
                          <div className="col-3">
                            <h5 className="allusers-text">₹ {parseFloat(agentDashboard?.data?.commission_wallet).toFixed(2)}</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default AgentDashboard;