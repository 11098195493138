import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { agentRegister } from "redux/Actions/Admin/User";
import { useDispatch, useSelector } from "react-redux";
import { setInitialState } from "redux/Reducers/Admin/UserSlice";

const SECRET_NAME = process.env.REACT_APP_SECRETAGENTNAME;

const Bettels = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const [hash, setHash] = useState(false);
  const secretName = SECRET_NAME;
  const sha512 = (str) => {
    return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
      return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
    });
  }
  const handleOnchange = (value) => {
    sha512(`${value}:0:${secretName}`).then(x => setHash(x));
  }

  const { isSuccess, error } = useSelector(state => state.adminUser);
  // console.log('error:::',error)
  useEffect(() => {
    if (isSuccess) {
      reset();
      setTimeout(() => {
        dispatch(setInitialState());
      }, 5000);
    }
  }, [isSuccess])

  const onSubmit = (data) => {
    // console.log('data', data);

    const regapi = {
      foreign_id: data.username,
      secret_name: secretName,
      wallet: 0,
      role_id: 6,
      hashed: hash
    }
    const final = {
      register: data,
      regapi: regapi
    }
    dispatch(agentRegister(final));
  }
  return (
    <>
      <main className="active-bg" id="agent_dashboard">
        <div className="container">
          <h1 className="create-user-active-caption">Create User Account</h1>
          {isSuccess &&
            <span className="success d-block">
              Register successfully
            </span>
          }
          <form onSubmit={handleSubmit(onSubmit)} className="register-form" >
            <div className="col-span-12 user_acc_bg">
              {/* <div className="mb-3">
                <label className="text-form-label font-semibold" htmlFor="profile_picture">Profile Photo</label>
                <input id="profile_picture" className="block px-2 py-2 mt-2 text-sm placeholder-gray-400" type="file" />
                <p className="text-gray-600 text-xs mt-1">You can upload PNG or JPG images format only</p>
              </div> */}
              <div className="mb-3">
                <label className="text-form-label font-semibold" htmlFor="full_name">Full Name (Activated)</label>
                <input placeholder="Full Name" type="text"
                  className="block w-full px-2 py-2 mt-2 placeholder-gray-400"
                  {...register("name", {
                    required: "Full name should not be empty",
                  })} />
                {errors.name && <span className="error d-block">{errors.name.message}</span>}
              </div>
              <div className="mb-3">
                <label className="text-form-label font-semibold" htmlFor="birthday">Birthday</label>
                <div className="relative w-auto">
                  <div className="relative rounded-md w-auto shadow-sm"></div>
                  <input placeholder="MM-DD-YYYY" type="date"
                    className="block w-full py-2 placeholder-gray-400"
                    {...register("date_of_birth", {
                      required: 'Date of birth should not be empty'
                    })} />
                  {errors.date && <span className="error d-block">{errors.date.message}</span>}
                </div>
              </div>
              <div className="mb-3">
                <label className="text-form-label font-semibold" htmlFor="gender_id">Gender</label>
                <select className="block w-full px-2 py-2 mt-2 placeholder-gray-400 bg-gray-300"
                  {...register("gender", {
                    required: 'Gender should not be empty'
                  })}>
                  <option value="">- Select Gender - </option>
                  <option value="Female">Female</option>
                  <option value="Male">Male</option>
                </select>
                {errors.gender && <span className="error d-block">{errors.gender.message}</span>}
              </div>
              <div className="mb-3">
                <label className="text-form-label font-semibold" htmlFor="username">Username</label>
                <input placeholder="username" type="text"
                  className="block w-full px-2 py-2 mt-2 placeholder-gray-400 bg-gray-200"
                  {...register("username", {
                    onChange: (e) => { handleOnchange(e.target.value); },
                    required: 'Username should not be empty'
                  })} />
                {errors.username ? <span className="error d-block">{errors.username.message}</span>
                  : error && Object.keys(error).length > 0 
                  && typeof error?.data !== 'undefined'
                  && Object.keys(error.data).length > 0
                  && error?.data?.username !== "undefined" && <span className="error d-block" >{error?.data?.username}</span>
                }
              </div>
              <div className="mb-3">
                <label className="text-form-label font-semibold" htmlFor="email">Email</label>
                <input placeholder="Email" type="email"
                  className="block w-full px-2 py-2 mt-2 placeholder-gray-400"
                  {...register("email", {
                    required: 'Email should not be empty'
                  })} />
                {errors.email ? <span className="error d-block">{errors.email.message}</span>
                  : error && Object.keys(error).length > 0 
                  && typeof error?.data !== 'undefined'
                  && Object.keys(error.data).length > 0
                  && error?.data?.email !== "undefined" && <span className="error d-block" >{error?.data?.email}</span>
                }
              </div>
              <div className="mb-3">
                <label className="text-form-label font-semibold" htmlFor="phone_number">Phone Number</label>
                {/* <input placeholder="ISD Code" className="block w-full px-2 py-2 mt-2 placeholder-gray-400r" name="phone_number" type="number" /> */}
                <input placeholder="Phone Number" type="number"
                  className="block w-full px-2 py-2 mt-2 placeholder-gray-400r"
                  {...register("phnumber", {
                    required: 'Phone number should not be empty'
                  })} />
                {errors.phnumber ? <span className="error d-block">{errors.phnumber.message}</span>
                : error && Object.keys(error).length > 0
                && typeof error?.data !== 'undefined'
                && Object.keys(error.data).length > 0 && error?.data?.phnumber !== "undefined" &&
                <span className="error d-block" >{error?.data?.phnumber}</span>}
              </div>
              <div className="mb-3">
                <label className="text-form-label font-semibold" htmlFor="access_level">User Type</label>
                <select className="block w-full px-2 py-2 mt-2 placeholder-gray-400"
                  {...register("user_type", {
                    required: 'User type should not be empty'
                  })}>
                  <option value="">Select Type</option>
                  <option value="3">User</option>
                  <option value="6">Agent</option>
                  {/* <option value="1">Master Agent</option> */}
                </select>
                {errors.user_type && <span className="error d-block">{errors.user_type.message}</span>}
              </div>
              {/* <div className="mb-3">
              <label className="text-form-label font-semibold" htmlFor="role">Role</label>
              <select id="role" className="block w-full px-2 py-2 mt-2 placeholder-gray-400" name="gender_id" fdprocessedid="w3d5j">
                <option>- Select Role - </option>
                <option value="2">1</option>
                <option value="1">2</option>
                <option value="1">3</option>
              </select>
            </div> */}
              {/* <div className="mb-3">
                <label className="text-form-label font-semibold" htmlFor="status">Status</label>
                <select className="block w-full px-2 py-2 mt-2 placeholder-gray-400"
                  {...register("user_status", {
                    required: 'User status should not be empty'
                  })}>
                  <option value={''}>- Select Status - </option>
                  <option value="2">Activated</option>
                  <option value="1">Cancelled</option>
                  <option value="1">Deactivated</option>
                  <option value="1">Processing</option>
                </select>
                {errors.user_status && <span className="error d-block">{errors.user_status.message}</span>}
              </div> */}
              <div className="mb-3">
                <label className="text-form-label font-semibold" htmlFor="password">Password</label>
                <input placeholder="Password" type="password"
                  className="block w-full px-2 py-2 mt-2 placeholder-gray-400"
                  {...register("password", {
                    required: 'Password should not be empty'
                  })} />
                {errors.password && <span className="error d-block">{errors.password.message}</span>}
              </div>
              {/* <div className="mb-3 flex gap-4">
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              <p>Verified Phone Number</p>
            </div>
            <div className="mb-3 flex gap-4">
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              <p>Verified Email Address</p>
            </div>
            <div className="mb-3 flex gap-4">
              <label className="switch">
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
              <p>Verified Home Address</p>
            </div> */}

              <div className="mb-3 flex gap-4">
                {/* <button className="flex-1 w-full border font-semibold text-gray-800 py-2 mt-2 user_acc-form-btn-submit" type="submit"> Cancel </button> */}
                <button className="flex-1 w-full border font-semibold text-gray-800 py-2 mt-2 user_acc-form-btn-submit" type="submit"> Save Changes </button>
              </div>
            </div>
          </form>
        </div>

      </main>
    </>
  )
}

export default Bettels;