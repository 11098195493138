import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "lib/api";

const getDashboard = createAsyncThunk(
  'admin/dashboard/admin',
  async () => {
    try {
      const response = await api.get(`/dashboard/admin`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)



export {
  getDashboard
};