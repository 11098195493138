import { createSlice } from "@reduxjs/toolkit";
import {
  agentDashboardData,
  walletTransactions,
  walletTransactionsApproval,
  getagentData
} from "redux/Actions/AgentDashboard";

const AgentDashbaordSlice = createSlice({
  name: "AgentDashboard",
  initialState: {
    isLoading: false,
    agentDashboard: false,
    isSuccess: false,
    error: {},
    transactionsData: {},
    agentData: {},
  },
  reducers: {
    setInitialState: (state) => {
      state.isSuccess = false;
      state.error = {};
    }
  },
  extraReducers: (builder) => {
    /* User Details */
    builder.addCase(agentDashboardData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.agentDashboard = action.payload.status === 200 ? action.payload.data : false;
    });
    builder.addCase(agentDashboardData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(agentDashboardData.rejected, (state, action) => {
      state.isLoading = false;
      state.agentDashboard = false;
    });

    /* wallet transactions data */
    builder.addCase(walletTransactions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.transactionsData = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(walletTransactions.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(walletTransactions.rejected, (state, action) => {
      state.isLoading = false;
      state.transactionsData = {};
    });

    /* wallet transactions approval */
    builder.addCase(walletTransactionsApproval.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSuccess = action.payload.status === 200 ? true : false;
      state.error = action.payload.status !== 200 ? action.payload.data : {};
    });
    builder.addCase(walletTransactionsApproval.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(walletTransactionsApproval.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    });
    /* wallet transactions approval */
    builder.addCase(getagentData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.agentData = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getagentData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getagentData.rejected, (state, action) => {
      state.isLoading = false;
    });

  }
});

export const {
  setInitialState
} = AgentDashbaordSlice.actions

export default AgentDashbaordSlice.reducer