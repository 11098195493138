import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserList, getCsvUser } from "redux/Actions/Admin/User";
import { setInitialState } from "redux/Reducers/Admin/UserSlice";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import Pagination from "components/Pagination";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { getAgentUserListMurga } from "redux/Actions/AgentUserList";

const Users = () => {

  const dispatch = useDispatch();
  const { isSuccess, csvUser, error, userList } = useSelector(state => state.adminUser);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('');
  const [dtFrom, setdtFrom] = useState('');
  const [dtTo, setdtTo] = useState('');
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const { agentListMurga } = useSelector(state => state.AgentUserListData);
  const [agent, setAgentList] = useState([]);
  useEffect(() => {
    if (Object.keys(agentListMurga).length > 0
      && Object.keys(agentListMurga.data).length > 0
    ) {
      setAgentList([...agentListMurga.data])
    }
  }, [agentListMurga])

  useEffect(() => {
    if (Object.keys(csvUser).length > 0
      && csvUser.data.length > 0) {
      setCsvData([...csvUser.data])
      setTimeout(() => {
        genReport.current.link.click();
        dispatch(setInitialState());
      }, 2000);
    }
  }, [csvUser])

  const genReport = useRef(null);
  const downloadReport = () => {
    let QST = `${search !== '' ? `&search=${search}` : ''}
    ${role !== '' ? `&role_id=${role}` : ''}
    ${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getCsvUser({
      page: 1,
      per_page: total,
      QST: QST
    }));
  }
  const csvReport = {
    data: csvData,
    headers: headers,
    filename: 'UserCsv.csv'
  };

  // console.log('dtFrom',dtFrom);
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        toast.success(`Status changed successfully`, {
          toastId: 'success',
          transition: Zoom
        });
        dispatch(setInitialState());
      }, 2000);
    }
    if (Object.keys(error).length > 0
      && typeof error.msg !== 'undefined') {
      setTimeout(() => {
        toast.error(error.msg, {
          toastId: 'success',
          transition: Zoom
        });
        dispatch(setInitialState());
      }, 2000);
    }
    let QST = `${search !== '' ? `&search=${search}` : ''}
    ${role !== '' ? `&role_id=${role}` : ''}
    ${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getUserList({
      page: currentPage,
      per_page: 10,
      QST: QST
    }));
  }, [isSuccess, error])
  useEffect(() => {
    let useName = [];
    if (Object.keys(userList).length > 0
      && Object.keys(userList.pagination).length
      && userList.pagination.current_page === 1
    ) {
      let thead = [];
      for (const key in userList.data[0]) {
        thead.push(
          { label: key, key: key },
        )
      }
      userList.data.map((item, index) => {
        useName.push(item.username);
      });
      let nuseName = useName.map(x => "'" + x.replace('-3', '') + "'").toString();
      dispatch(getAgentUserListMurga({ username: nuseName }));

      setHeaders(thead)
      setTotal(userList.pagination.total);
      setPerPage(userList.pagination.per_page);
    }
  }, [userList]);
  useEffect(() => {
    let QST = `${search !== '' ? `&search=${search}` : ''}
    ${role !== '' ? `&role_id=${role}` : ''}
    ${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getUserList({
      page: currentPage,
      per_page: 10,
      QST: QST
    }));
  }, [currentPage]);

  useEffect(() => {
    dispatch(getUserList({
      page: currentPage,
      per_page: 10,
      QST: ""
    }))
  }, []);
  const handleFilter = () => {
    let QST = `${search !== '' ? `&search=${search}` : ''}
    ${role !== '' ? `&role_id=${role}` : ''}
    ${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getUserList({
      page: 1,
      per_page: 10,
      QST: QST
    }));
  }


  return (
    <>
      <ToastContainer transition={Zoom} position={toast.POSITION.TOP_CENTER} />
      <main className="active-bg" id="user-management">
        <div className="container-fluid">
          <div className="row">
            <div className="table-wrapper">
              <div className="table-container">
                <div className="row mt-4 mb-4" id="users_">
                  <div className="col-12 col-md-6 col-lg-6">
                    <h1 className="active-caption">User Management</h1>
                  </div>
                  <div className="col-12 col-md-6 col-lg-6 text-end">
                    <div className="add_newuser_button">
                      <a href="/admin/user/add" className="btn btn-primary user_add_new_btn">Add New +</a>
                    </div>
                  </div>
                </div>

                <div className="search-box-bg">
                  <div className="flex justify-between px-4 gap-2 mb-2">
                    <input className=" flex-1 px-3 py-2 border text-sm placeholder-gray-400 search-text" placeholder="Search (e.g. Name, Username, Email etc)"
                      type="text" onChange={(e) => setSearch(e.target.value)} />
                    <select className="flex-1 px-3 py-2 border placeholder-gray-400 dropdown_role_id"
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value="">- Roles (All) -</option>
                      <option value="3">User</option>
                      <option value="5">Agent</option>
                    </select>
                    <select className="flex-1 px-3 py-2 border dropdown_role_id placeholder-gray-400"
                      onChange={(e) => setStatus(e.target.value)}>
                      <option value="">- Status (All) -</option>
                      <option value="Pending">Pending</option>
                      <option value="Activated">Activated</option>
                      <option value="Suspended">Suspended</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Archived">Archived</option>
                      <option value="Deactivated">Deactivated</option>
                    </select>

                    <div className="relative w-auto">
                      <div className="date-container">
                        <input type="date" name="date"
                          onChange={(e) => setdtFrom(moment(e.target.value).format('MMMM D, YYYY'))} />
                      </div>
                    </div>

                    <div className="relative w-auto">
                      <input type="date" name="date"
                        onChange={(e) => setdtTo(moment(e.target.value).format('MMMM D, YYYY'))} />
                    </div>

                    <button onClick={() => downloadReport()}
                      className="flex-2 px-6 py-2 text-sm text-white apply_filter_button">
                      Generate Report
                    </button>
                    <CSVLink {...csvReport} ref={genReport} style={{ display: 'none' }}>
                      Generate Report
                    </CSVLink>

                    <button className="flex-2 px-6 py-2 text-sm text-white apply_filter_button"
                      onClick={() => handleFilter()}> Apply Filters </button>
                  </div>
                </div>

                <table>
                  <tbody>
                    <tr>
                      <th>S.No</th>
                      <th>USERNAME</th>
                      <th>SPONSOR</th>
                      <th>ROLE</th>
                      <th>STATUS</th>
                      {/* <th>DETAILS</th> */}
                      <th>COM.WALLET</th>
                      <th>WALLET</th>
                      <th>COM(%)</th>
                      <th>ACTION</th>
                    </tr>
                    {Object.keys(userList).length > 0 &&
                      userList.data.length > 0 ?
                      userList.data.map((item, index) => {
                        let usena = item.username;
                        usena = usena.replace('-3', '');
                        const OA = agent.find(item => item.username == usena);
                        return (<tr key={`ad${index}`}>
                          <td data-cell="ID">{(currentPage * perPage) + index + 1 - perPage}</td>
                          <td data-cell="USERNAME">{OA?.username ? OA?.username : item?.username}</td>
                          <td data-cell="SPONSOR">{item.sponsor_full_name}</td>
                          <td data-cell="ROLE">{item.role}</td>
                          <td data-cell="STATUS"><span className="activate_green"> {item.status} </span></td>
                          {/* <td data-cell="DETAILS"> </td> */}
                          <td data-cell="COM.WALLET">₹{parseFloat(item.commission_wallet).toFixed(2)}</td>
                          <td data-cell="WALLET">₹{parseFloat(item.wallet).toFixed(2)}</td>
                          <td data-cell="COM(%)">{item.commission_percent}</td>
                          <td data-cell="ACTION">
                            <div className="row">
                              <div className="col-12">
                                <Link to={`/admin/user/${item.username}`} >
                                  <button className="user-management-btn">
                                    <span> Details</span>
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>)
                      })
                      :
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={9} >No Data Found</td>
                      </tr>
                    }
                  </tbody>
                </table>
                {(total > perPage) && (
                  <div className="setPagination pb-1 pe-4 pt-3">
                    <Pagination
                      perPage={perPage}
                      total={total}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Users;