import React from "react";
import { io } from "socket.io-client";
// import { useDispatch, useSelector } from "react-redux";
// import { apiTestList } from "redux/Actions/Authorized";

const TestApi = () => {

    let options = {
        reconnection: true,
        forceNew: true,
        transports: ['websocket'],
        query:{
            token:"eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJhdXRoIiwiYXVkIjoic2VydmVyIiwiaXNzIjoiY2xpZW50IiwianRpIjoibXlyaFhNRmlCY2s3TDNHcmVwQmJtSmNVRU9IWGduSEUiLCJleHAiOjE2ODcwMTgyMDEsIm5iZiI6MTY4NTQ2MzAwMSwiaWF0IjoxNjg1NzIyMjAxfQ.CR7BCi2UgmbYp4VBip1vAeeTMToE0cyY-8UqWTqdmYx2cfZRSNijgJ_2xbmvcuLz66lRuzbda0bUT1FMSRiw_g",
            uuid: "myrhXMFiBck7L3GrepBbmJcUEOHXgnHE"
        },
        path: '/QMy9Mgxq3kzSwdyK/'
    };
    // return false;
    let yourSocket = io("https://api.fightarena.live/", options);
    yourSocket.on("1676611631948-fBD1QilH0N1hL5qtOXbyzHYwXVeklUd4cqMOrGsL1pwqTyyxyxPFp6JccYTUocKFD51YyFtVHODM1VXKQa27CvjYvbkxDHXKCkvyBGSiGKtRNimgb0svAx81", (rec) => {
         console.log('socket',rec);
    
        // event name can be access thru rec.event
    // StartBet = New bet started
    // LiveBet = Other player place a new bet
    // FinalBet = The game is closed or the winner is posted
    // BattleInfo = New battle information
    // RevertBattle = Battle is reverted/cancelled
    // event data can be access thru rec.data
    });
    yourSocket.on(`arena_1`, (rec) => {

        console.log('arean',rec);
    // event name can be access thru rec.event
    // Bet = Betting information
    // FinalBet = The game is closed or the winner is posted
    });
    
    // const dispatch = useDispatch();
    // // const zdsdj = useSelector(state => state.authorized);
    // const { isLoading, apiTestData } = useSelector(state => state.authorized);

    // console.log('apiTestData',apiTestData);
    // const [respn,setRespon] = useState(false);
    

    // const apidata = {
    //     foreign_id:"123abc",
    //     secret_name:"2SQMxpiOra02AqCja18l1MteNXuV8Y5jG4UlJVJ26STs8sFfAhfuLRFABShRTONx15mC2NEoaLlec8GfsLolQVAflzc6T129bdHJ91mglSQF5cv",
    //     wallet:100,
    //     role_id:3,
    //     hashed:"fd24e526ab85a7aa0c82291e430a93365e86c96c11655fa03c2146eeafff27092dc4fcb66d34f273792e9daf68f7784a4b10b601093adcc758d9b8625979f61b"
    // }
    // useEffect(() => {       
    //     dispatch(apiTestList(apidata));
    // },[]);
   
    return (<>fff</>);
}

export default TestApi;