import React, { useEffect } from "react";
import Homebanner_image from "images/userbanner1.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getBattleData } from "redux/Actions/Battles";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { getUserProfileData } from "redux/Actions/Userporfile";

const Userhome = () => {
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.loggedin);
  useEffect(() => {
    let arenaid = userData.data.arena_id;
    if (arenaid === null) {
      arenaid = 1;
    }
    dispatch(getBattleData(arenaid));
    const username = userData.data.user.username;
    dispatch(getUserProfileData(username));
  }, [dispatch,userData])

  return (
    <>
      <ToastContainer transition={Zoom} position={toast.POSITION.TOP_CENTER} autoClose={1000} />
      <section className="user_home_banner" >
        <div className="user_homebannerimage" style={{ backgroundImage: `url("${Homebanner_image}")` }}>
          <div className="user_homebannerimage_textwrapper">
            <div className="userhome_bannerheading">
              <h2>NEW GAME WILL STARTS IN</h2>
            </div>
            <div className="userhome_bannertimmer">
              <div className="countdown">
                <div>
                  <span className="number hours">09</span>
                  <span className="text-light">Hours</span>
                </div>
                <div>
                  <span className="number minutes">35</span>
                  <span className="text-light">Minutes</span>
                </div>
                <div>
                  <span className="number seconds">13</span>
                  <span className="text-light">Seconds</span>
                </div>
              </div>
            </div>
            <div className="enterthegame">
              <Link to={"/arena"}>Play Game</Link>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Userhome;