import { createSlice } from "@reduxjs/toolkit";

const LoggedIn = createSlice({
  name: "loggedIn",
  initialState: {
    isLoggedin: false,
    userData: false,
    wallet: 0,
  },
  reducers: {
    setIsLoggedin: (state, action) => {
      if (Object.keys(action.payload).length > 0
        && Object.keys(action.payload.data).length > 0
        && Object.keys(action.payload.data.gatekeeper).length > 0
        && action.payload.data.gatekeeper.token !== '') {
        state.isLoggedin = true;
        state.userData = action.payload;
        localStorage.setItem("token", action.payload.data.gatekeeper.token);
      }
    },
    setLogout: (state) => {
      state.isLoggedin = false;
    },
    setWallet: (state,action) => {      
      state.wallet = action.payload;
    },
  },
  extraReducers: (builder) => {

  }
});


export const {
  setIsLoggedin,
  setLogout,
  setWallet
} = LoggedIn.actions

export default LoggedIn.reducer