import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBetList, getCsvBet } from "redux/Actions/Admin/Bets";
import { setInitialState } from "redux/Reducers/Admin/BetsSlice";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import Pagination from "components/Pagination";
import moment from "moment";
import { CSVLink } from "react-csv";

const Bets = () => {
  const dispatch = useDispatch();
  const { isSuccess,
    csvBet,
    error,
    betList } = useSelector(state => state.adminBet);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState('');
  const [dtFrom, setdtFrom] = useState('');
  const [dtTo, setdtTo] = useState('');
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  useEffect(() => {
    if (Object.keys(csvBet).length > 0
      && csvBet.data.length > 0) {
      setCsvData([...csvBet.data])
      setTimeout(() => {
        genReport.current.link.click();
        dispatch(setInitialState());
      }, 2000);
    }
  }, [csvBet])

  const genReport = useRef(null);
  const downloadReport = () => {
    let QST = `${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getCsvBet({
      page: 1,
      per_page: total,
      QST: QST
    }));
  }
  const csvReport = {
    data: csvData,
    headers: headers,
    filename: 'BetCsv.csv'
  };
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        toast.success(`Status changed successfully`, {
          toastId: 'success',
          transition: Zoom
        });
        dispatch(setInitialState());
      }, 2000);
    }
    if (Object.keys(error).length > 0
      && typeof error.msg !== 'undefined') {
      setTimeout(() => {
        toast.error(error.msg, {
          toastId: 'success',
          transition: Zoom
        });
        dispatch(setInitialState());
      }, 2000);
    }
    let QST = `${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getBetList({
      page: currentPage,
      per_page: 10,
      QST: QST
    }));
  }, [isSuccess, error])
  useEffect(() => {
    if (Object.keys(betList).length > 0
      && Object.keys(betList.pagination).length
      && betList.pagination.current_page === 1
    ) {
      let thead = [];
      for (const key in betList.data[0]) {
        thead.push(
          { label: key, key: key },
        )
      }
      setHeaders(thead)
      setTotal(betList.pagination.total);
      setPerPage(betList.pagination.per_page);
    }
  }, [betList]);
  useEffect(() => {
    let QST = `${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getBetList({
      page: currentPage,
      per_page: 10,
      QST: QST
    }));
  }, [currentPage]);

  useEffect(() => {
    dispatch(getBetList({
      page: currentPage,
      per_page: 10,
      QST: ""
    }))
  }, []);
  const handleFilter = () => {
    let QST = `${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getBetList({
      page: 1,
      per_page: 10,
      QST: QST
    }));
  }
  return (
    <>
      <ToastContainer transition={Zoom} position={toast.POSITION.TOP_CENTER} />
      <main className="active-bg" id="bet-histories">
        <div className="container-fluid">
          <div className="row">
            <div className="table-wrapper">
              <div className="table-container">
                <h1 className="active-caption-cash">Bet Histories</h1>
                <div className="flex search-box-bg justify-between px-4 gap-2 mb-2">
                  <select className="flex-1 px-3 py-2 border dropdown_role_id placeholder-gray-400"
                    onChange={(e) => setStatus(e.target.value)}>
                    <option value="">- Status (All) -</option>
                    <option value="Pending">Pending</option>
                    <option value="Win">Win</option>
                    <option value="Loss">Loss</option>
                    <option value="Draw">Draw</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="Reverted">Reverted</option>
                  </select>
                  <div className="relative w-auto">
                    <div className="date-container">
                      <input type="date" name="date"
                        onChange={(e) => setdtFrom(moment(e.target.value).format('MMMM D, YYYY'))} />
                    </div>
                  </div>

                  <div className="relative w-auto">
                    <input type="date" name="date"
                      onChange={(e) => setdtTo(moment(e.target.value).format('MMMM D, YYYY'))} />
                  </div>
                  <button onClick={() => downloadReport()}
                    className="flex-2 px-6 py-2 text-sm text-white apply_filter_button">
                    Generate Report
                  </button>
                  <CSVLink {...csvReport} ref={genReport} style={{ display: 'none' }}>
                    Generate Report
                  </CSVLink>
                  <button className="flex-2 px-6 py-2 text-sm text-white apply_filter_button"
                    onClick={() => handleFilter()}> Apply Filters </button>
                </div>


                <table>
                  <tbody>
                    <tr>
                      <th>S.No</th>
                      <th>FIGHT #</th>
                      <th>USERNAME</th>
                      <th>BET AMOUNT</th>
                      <th>AVAILABLE WALLET</th>
                      <th>NET PAY</th>
                      <th>TO</th>
                      <th>STATUS</th>
                      <th>FLAG</th>
                      <th>BATTLE CLOSED AT</th>
                      <th>DATE</th>
                    </tr>
                    {Object.keys(betList).length > 0 &&
                      betList.data.length > 0 ?
                      betList.data.map((item, index) => {
                        return (
                          <tr key={`adb${index}`}>
                            <td data-cell="S.No." data-label="S.no.">{(currentPage * perPage) + index + 1 - perPage}</td>
                            <td data-cell="Fight#" data-label="fight#">{item?.fight_number}</td>
                            <td data-cell="Username" data-label="Username">{item?.username}</td>
                            <td data-cell="Bet Amount" data-label="Bet Amount">{item?.amount}</td>
                            <th data-cell="Available Wallet" data-label="Available Wallet">{item.available_wallet}</th>
                            <td data-cell="Net PAY" data-label="Net Pay">₹{parseFloat(item?.net_pay).toFixed(2)}</td>
                            <th data-cell="To" data-label="To">{item.to}</th>
                            <td data-cell="Status" data-label="Status">{item?.status}</td>
                            <th data-cell="Flag" data-label="Flag">FLAG</th>
                            <td data-cell="Battle Closed At" data-label="Battle Close At">{moment(item?.battle_closed_at).format('DD-MM-YYYY hh:mm:ss')}</td>
                            <td data-cell="Date" data-label="date">{moment(item?.created_at).format('DD-MM-YYYY hh:mm:ss')}</td>
                          </tr>
                        )
                      })
                      :
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={9} >No Data Found</td>
                      </tr>
                    }
                  </tbody>
                </table>
                {(total > perPage) && (
                  <div className="setPagination pb-1 pe-4 pt-3">
                    <Pagination
                      perPage={perPage}
                      total={total}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Bets;