import React, { useEffect, useState } from "react";
import LoginImage from "images/Login.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getArenaData, betCreate, getBattleData } from "redux/Actions/Battles";
import { setInitialState, setSocketEvent } from "redux/Reducers/BattlesSlice";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { getUserBetHistoryData } from "redux/Actions/UserBetHistory";
import { setWallet } from "redux/Reducers/LoggedSlice"
import { io } from "socket.io-client";
import Pagination from "components/Pagination";

const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;
const SOCKET_KEY = process.env.REACT_APP_SOCKET_KEY;

const Dashboard = () => {

  const dispatch = useDispatch();
  const { userData, wallet } = useSelector(state => state.loggedin);
  const { userBetHistoryData } = useSelector(state => state.userBetHistoryData);
  const { userProfileData } = useSelector(state => state.userProfileData);
  const { arenaData,
    battleData,
    betInfo,
    error
  } = useSelector(state => state.battle);

  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (Object.keys(userBetHistoryData).length > 0
      && Object.keys(userBetHistoryData.pagination).length
      && userBetHistoryData.pagination.current_page === 1
    ) {
      setTotal(userBetHistoryData.pagination.total);
      setPerPage(userBetHistoryData.pagination.per_page);
    }
  }, [userBetHistoryData]);
  useEffect(() => {
    const data = `player_id=${userData.data.user.id}&page=${currentPage}&per_page=12`;
    dispatch(getUserBetHistoryData(data));
  }, [currentPage]);



  const [socketUUID, setSocketUUID] = useState(0);
  const [socketArenaid, setSocketArenaid] = useState(1);
  // const [walletAmonut, setWalletAmount] = useState(false);
  const [cRedPayout, setCRedPayout] = useState(0);
  const [cBluePayout, setCBluePayout] = useState(0);

  const [cRed, setCRed] = useState(0);
  const [cBlue, setCBlue] = useState(0);

  useEffect(() => {
    if (Object.keys(userProfileData).length > 0
      && Object.keys(userProfileData.data).length > 0
    ) {
      // setWalletAmount(parseFloat(userProfileData.data.wallet).toFixed(2));
      dispatch(setWallet(parseFloat(userProfileData.data.wallet).toFixed(2)));
    }
  }, [])

  useEffect(() => {
    if (Object.keys(betInfo).length > 0
      && Object.keys(betInfo.data).length > 0
      && betInfo.success
    ) {
      toast.success(`${amount} bet place on ${type}`, {
        toastId: 'amount',
        transition: Zoom
      });
      setTimeout(() => {
        dispatch(setInitialState());
      }, 3000);

      setCRed(betInfo.data.totalM);
      setCBlue(betInfo.data.totalW);
    }
  }, [betInfo])

  useEffect(() => {
    if (Object.keys(error).length > 0
    ) {
      toast.success(`${error.msg} `, {
        toastId: 'error_msg',
        transition: Zoom
      });
      setTimeout(() => {
        dispatch(setInitialState());
      }, 3000);

    }
  }, [error])

  useEffect(() => {
    if (Object.keys(betInfo).length > 0
      && Object.keys(betInfo.data).length > 0
    ) {
      // setWalletAmount(parseFloat(betInfo.data.wallet).toFixed(2));
      dispatch(setWallet(parseFloat(betInfo.data.wallet).toFixed(2)));
    }
  }, [betInfo])
  // console.log('betInfo',betInfo)
  useEffect(() => {
    let arenaid = userData.data.arena_id;
    if (arenaid === null) {
      arenaid = 1;
    }
    setSocketArenaid(arenaid);
    dispatch(getArenaData(arenaid));
    dispatch(getBattleData(arenaid));
    if (Object.keys(userData).length > 0
      && Object.keys(userData.data).length > 0
      && Object.keys(userData.data.gatekeeper).length > 0
    ) {
      setSocketUUID(userData.data.gatekeeper.uuid);
    }

    if (Object.keys(userData).length > 0
      && Object.keys(userData.data).length > 0
      && Object.keys(userData.data.user).length > 0
    ) {
      const data = `player_id=${userData.data.user.id}&page=${currentPage}&per_page=12`;
      dispatch(getUserBetHistoryData(data));
    }
  }, [dispatch, userData]);


  const [amount, setAmount] = useState(false);
  const [amountRed, setAmountRed] = useState(false);
  const [amountBlue, setAmountBlue] = useState(false);
  const [type, setType] = useState(false);

  const betAmount = [50, 100, 500];

  const handlleAmount = (value) => {
    setAmountRed(value);
  }
  const handlleAmountBlue = (value) => {
    setAmountBlue(value);
  }

  const [betStatus, setBetStatus] = useState(false);
  const [fightNumber, setFightNumber] = useState(false);
  const [inital, setInital] = useState(true);

  useEffect(() => {
    if (Object.keys(battleData).length > 0
      && Object.keys(battleData.data).length
    ) {
      // console.log(battleData);
     
      setCRed(0);
      setCBlue(0);
      setBetStatus(battleData.data.status);

      setFightNumber(battleData.data.fight_number);
      if (!inital && battleData.data.status !== "Pending") {
        let message = battleData.data.status === "Cancelled" ?
          "Battle has been cancelled" : battleData.data.status === "Closed" ? "Batting is Closed. Final Result pass shortly" : `Winner ${battleData.data.status}`;
        toast.success(`${message} `, {
          toastId: 'success_msg',
          transition: Zoom
        });
        setInital(true);
      }
    }
  }, [battleData])



  let options = {
    reconnection: true,
    forceNew: true,
    transports: ['websocket'],
    query: {
      token: localStorage.getItem("token"),
      uuid: socketUUID
    },
    path: SOCKET_KEY
  };

  const [run, setRun] = useState(false);
  useEffect(() => {
    setInterval(() => {
      setRun(!run);
    }, 2000);
  }, [])
  const socket = io(SOCKET_URL, options);

  useEffect(() => {
    console.log('yes')
    socket.on("connect", () => {
      console.log('connected')
      socket.on(`arena_${socketArenaid}`, (rec) => {
        console.log('arena hit');
        setInital(false);
        console.log(rec)
        if (rec.event === "StartBet") {
          let data = {
            battle: { data: rec.data.battle },
            arena: { data: rec.data.arena }
          }
          dispatch(setSocketEvent(data))
          setCRedPayout(0);
          setCBluePayout(0);
        }
        if (rec.event === "LiveBet") {
          setCRedPayout(rec.data.bm);
          setCBluePayout(rec.data.bw);
        }
        if (rec.event?.data?.status === "Meron") {
          setCRedPayout(false);
          setCBluePayout(false);
        }
        if (rec.event?.data?.status === "Wala") {
          setCRedPayout(false);
          setCBluePayout(false);
        }
        if (rec.event === "FinalBet") {
         
          setAmount(false);
          setAmountRed(false);
          setAmountBlue(false);
         
          const data = `player_id=${userData.data.user.id}&page=${currentPage}&per_page=12`;
          dispatch(getUserBetHistoryData(data));
          dispatch(getBattleData(socketArenaid));
        }
      });
    });
    return () => socket.disconnect();
  }, [run]);

  const [steamUrl, setSteamUrl] = useState(false);
  useEffect(() => {
    if (arenaData && Object.keys(arenaData.data).length > 0) {
      setSteamUrl(arenaData.data.stream_url)
    }
  }, [arenaData])

  const betNow = (value) => {
    if (betStatus && betStatus !== "Pending") {
      toast.error(`Betting Closed
      New Game start shortly`, {
        toastId: 'battle_closed',
        transition: Zoom
      });
      return false;
    }
    const tempamt = value === 'Meron' ? amountRed : value === 'Wala' ? amountBlue : 0;
    const amount1 = parseFloat(tempamt);
    if (amount1 && amount1 > 0) {
      // if (walletAmonut >= amount1) {
      const data = {
        "battle_id": battleData.data.id,
        "to": value,
        "amount": amount1
      }
      setAmount(amount1);
      setType(value);
      dispatch(betCreate(data));
    } else {
      toast.error('Amount should be grater then 0.', {
        toastId: 'amount',
        transition: Zoom
      });
    }
  }

  // console.log('betStatus', betStatus);
  return (
    <>
      <ToastContainer transition={Zoom} position={toast.POSITION.TOP_CENTER} />
      <section className="dashboard-bg-image areascreen_dasd"
        style={{ backgroundImage: `url("${LoginImage}")` }}>
        <div className="dashboard_Wrapper">
          <div className="dashboard_video_wrapper">
            <div className="dashboard_video_wrapper_item">
              <div className="news_api_sad">
                <iframe width="180" height="100" src="https://ott.livelegitpro.in/pusa/sudarshantv/embed.html?autoplay=1&amp;controls=0&amp;showinfo=0&amp;modestbranding=0&amp;mute=1" frameBorder="0" allow="autoplay; "></iframe>
              </div>
              <div className="video_player">
                <iframe src={steamUrl} title="Strem video player"></iframe>
              </div>
            </div>
          </div>
          <div className="dashboard_text_wrapper">
            <div className="dashboard_video_wrapper_item">
              <div className="dashboard_fight_details">
                <div className="status_main_bg">
                  <div className="row">
                    <div className="col-12 col-md-6 col-lg-6 status_upper_bg status_border_right">
                      <p className="text-light">FIGHT STATUS</p>
                      <p><span className="closed_status">{betStatus && betStatus !== "Pending" ? <> Closed</> : <>Open</>}</span></p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 status_upper_bg status_border_left">
                      <p className="text-light">FIGHT No</p>
                      <p className="text-light">{fightNumber && fightNumber}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard_video_wrapper_item_wrapper">

                <div className="betting_open1">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="betbutton_red">
                          <div className="betbutton_red_heading">
                            Red
                          </div>
                          <div className="betbutton_red_wrapper">
                            <div className="row-1">
                              <div className="col-md-12">
                                {betStatus && betStatus !== 'Cancelled' && betStatus !== 'Closed'
                                  && (betStatus === "Pending" || betStatus !== "Meron")
                                  &&
                                  <div className="bet_wrapper_iner">
                                    <div className="bet_wrapper_iner_top">
                                      <div className="bet_wrapper_iner_top_text">
                                        <span>Bet</span>
                                        <input type={"number"} onChange={
                                          (e) => handlleAmount(e.target.value)}
                                          value={amountRed} />
                                      </div>
                                    </div>
                                    <div className="bet_wrapper_iner_bottom">
                                      <div className="bet_wrapper_iner_bottom_text">
                                        {betAmount.map((item, index) => {
                                          return (
                                            <div key={`test${index}`} className={item === amount ? `bet_value_text active` : `bet_value_text`} onClick={
                                              () => handlleAmount(item)}> {item} </div>
                                          )
                                        })}
                                        <div className={wallet === amount
                                          ? `bet_value_text active` : `bet_value_text`}
                                          onClick={() => handlleAmount(wallet)}> All
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                                {betStatus && betStatus !== "Pending"
                                  && (betStatus === 'Meron'
                                    || betStatus === 'Cancelled'
                                    || betStatus === 'Closed') &&
                                  <div className="bet_wrapper_iner">
                                    <div className="bet_wrapper_iner_top">
                                      <div className="bet_wrapper_iner_top_text">
                                        {betStatus && betStatus !== "Pending"
                                          ? <><span className="custom_message_error_we"> Closed</span>
                                            <span className="custom_message_error_we_number">{fightNumber && fightNumber}</span></>
                                          : <><span className="custom_message_error_we"> Open</span>
                                            <span className="custom_message_error_we_number">{fightNumber && fightNumber}</span></>
                                        }
                                      </div>
                                    </div>
                                    {betStatus === 'Meron'
                                      && betStatus !== 'Cancelled'
                                      && betStatus !== 'Closed' &&
                                      <div className="betwiiner">Winner</div>
                                    }
                                    {(betStatus === 'Cancelled') &&
                                      <div className="betwiiner">Cancelled</div>
                                    }
                                    {(betStatus === 'Closed') &&
                                      <div className="betwiiner">Closed</div>
                                    }
                                  </div>
                                }
                              </div>
                              <div className="col-md-12">
                                <div className="user_dashboard_payout">
                                  <div className="user_dashboard_payout_inner">
                                    <div className="user_dashboard_payout_heading">
                                      Payout
                                    </div>
                                    <div className="user_dashboard_payout_heading">
                                      {battleData?.data?.net_pay > 0 && betStatus === 'Meron' ? battleData.data.net_pay : cRedPayout}
                                    </div>
                                  </div>
                                  <div className="user_dashboard_payout_inner">
                                    <div className="user_dashboard_payout_heading">
                                      {cRed}
                                    </div>
                                  </div>
                                </div>
                                <div className="bet_now_button">
                                  <div className="bet_now_button_bet" onClick={() => betNow('Meron')} disabled>
                                    Bet Now
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="betbutton_red">
                          <div className="betbutton_red_heading blue">
                            Blue
                          </div>
                          <div className="betbutton_red_wrapper">
                            <div className="row-1">
                              <div className="col-md-12">
                                {betStatus && betStatus !== 'Cancelled' && betStatus !== 'Closed' && (betStatus === "Pending" || betStatus !== 'Wala')
                                  &&
                                  <div className="bet_wrapper_iner">
                                    <div className="bet_wrapper_iner_top">
                                      <div className="bet_wrapper_iner_top_text">
                                        <span>Bet</span>
                                        <input type={"number"} onChange={
                                          (e) => handlleAmountBlue(e.target.value)}
                                          value={amountBlue} />
                                      </div>
                                    </div>
                                    <div className="bet_wrapper_iner_bottom">
                                      <div className="bet_wrapper_iner_bottom_text">
                                        {betAmount.map((item, index) => {
                                          return (
                                            <div key={`ab${index}`} className={item === amount ? `bet_value_text active` : `bet_value_text`} onClick={
                                              () => handlleAmountBlue(item)}> {item} </div>
                                          )
                                        })}
                                        <div className={wallet === amount
                                          ? `bet_value_text active` : `bet_value_text`}
                                          onClick={() => handlleAmountBlue(wallet)}> All
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                }
                                {betStatus && betStatus !== "Pending"
                                  && (betStatus === 'Wala' || betStatus === 'Cancelled'
                                    || betStatus === 'Closed') &&
                                  <div className="bet_wrapper_iner">
                                    <div className="bet_wrapper_iner_top">
                                      <div className="bet_wrapper_iner_top_text">
                                        {betStatus && betStatus !== "Pending"
                                          ? <><span className="custom_message_error_we"> Closed</span>
                                            <span className="custom_message_error_we_number">{fightNumber && fightNumber}</span></>
                                          : <><span className="custom_message_error_we"> Open</span>
                                            <span className="custom_message_error_we_number">{fightNumber && fightNumber}</span></>
                                        }
                                      </div>
                                    </div>
                                    {betStatus === 'Wala' &&
                                      <div className="betwiiner blue">Winner</div>
                                    }
                                    {(betStatus === 'Cancelled') &&
                                      <div className="betwiiner blue">Cancelled</div>
                                    }
                                    {(betStatus === 'Closed') &&
                                      <div className="betwiiner blue">Closed</div>
                                    }
                                  </div>
                                }
                              </div>
                              <div className="col-md-12">
                                <div className="user_dashboard_payout">
                                  <div className="user_dashboard_payout_inner">
                                    <div className="user_dashboard_payout_heading">
                                      Payout
                                    </div>
                                    <div className="user_dashboard_payout_heading">
                                      {battleData?.data?.net_pay > 0 && betStatus === 'Wala' ? battleData.data.net_pay : cBluePayout}
                                    </div>
                                  </div>
                                  <div className="user_dashboard_payout_inner">
                                    <div className="user_dashboard_payout_heading">
                                      {cBlue}
                                    </div>
                                  </div>
                                </div>
                                <div className="bet_now_button">

                                  <div className="ellipse"></div>

                                  <div className="bet_now_button_bet blue" onClick={() => betNow('Wala')}>
                                    Bet Now
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard_item_text">
          <div className="table_outer_container">
            <h5 className="text-light table-bgblue-text m-0 p-3 text-center"> <strong> Bet List</strong></h5>
            <table className="table table-bordered p-2 betlist_table">
              <thead>
                <tr className="text-center">
                  <th scope="col-4 justify-content-center">Fight Number</th>
                  <th scope="col-4 justify-content-center">Battle Name</th>
                  <th scope="col-4 justify-content-center">BET (RS)</th>
                  <th scope="col-4 justify-content-center">BET ROOSTER</th>
                  <th scope="col-4 justify-content-center">RESULTS</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {typeof userBetHistoryData !== 'undefined' && Object.keys(userBetHistoryData).length > 0 &&
                  Object.keys(userBetHistoryData.data).length &&
                  userBetHistoryData.data.map((item, index) => {
                    return (<tr key={index}>
                      <th data-cell="Fight Number" data-label="Fight Number" scope="row">{item.fight_number}</th>
                      <td data-cell="Battle Number" data-label="Battle Number">{item.battle_name}</td>
                      <td data-cell="BET" data-label="BET">{item.amount}</td>
                      <td data-cell="BET ROOSTER" data-label="BET ROOSTER">{item.to}</td>
                      <td data-cell="RESULTS" data-label="RESULTS">{item.status}</td>
                    </tr>)
                  })}

              </tbody>
            </table>
            {(total > perPage) && (
              <div className="setPagination pb-1 pe-4 pt-3">
                <Pagination
                  perPage={perPage}
                  total={total}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </div>
      </section>

      {/* <div className="notification_popup">
        <div className="notification_text">
          <h4>Winner</h4>
          <p>Achal</p>
        </div>
      </div> */}
    </>
  )
}

export default Dashboard;