import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, murgaApi } from "lib/api";


const getAgentUserListMurga = createAsyncThunk(
  'userlist/murga',
  async (data) => {
    try {
      const response = await murgaApi.post(`auth/user-list`, data)
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const getAgentUserList = createAsyncThunk(
  'userlist',
  async (data) => {
    try {
      const response = await api.get(`/users?page=${data.page}&per_page=${data.per_page}${data.QST}`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)
const userChangeStatus = createAsyncThunk(
  'user/change/status',
  async (data) => {
    try {
      const response = await api.patch(`/user/change/status`, data, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

export {
  getAgentUserListMurga,
  getAgentUserList,
  userChangeStatus
};