import React from "react";
import AgentHeader from "components/Common/AgentHeader";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoutesAgent = ({ component: Component, ...rest }) => {

  const { isLoggedin, userData } = useSelector(state => state.loggedin);

  return (
    isLoggedin &&
      Object.keys(userData).length > 0 &&
      Object.keys(userData.data).length > 0 &&
      Object.keys(userData.data.user).length > 0 &&
      (userData.data.user.role_id === 5
        || userData.data.user.role_id === 6
      )
      ?
      <>
        <AgentHeader />
        <Component {...rest} />
      </>
      : <Navigate to="/" />
  )
}

export default ProtectedRoutesAgent;