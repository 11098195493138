import axios from "axios";
const API_URL = process.env.REACT_APP_API_BASE;
const MURGA_API_URL = process.env.REACT_APP_API_MURGA_NODE;
const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-type": "application/json",
  },
});

const murgaApi = axios.create({
  baseURL: MURGA_API_URL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin":'*',
  },
});
// const apiMultiPart = axios.create({
//   baseURL: API_URL,
//   headers: {
//     "Content-type": "multipart/form-data",
//   },
// });

// export default api;
export {api, murgaApi}