import { createSlice } from "@reduxjs/toolkit";
import {
  getUserTransactionData,
  transactionCreate
} from "redux/Actions/UserTransaction";

const userTransactionSlice = createSlice({
  name: "getUserTransactionData",
  initialState: {
    isLoading: false,
    userTransactionData: false,
    isSuccess: false,
    error: false,
  },
  reducers: {
    setInitialState: (state) => {
      state.isSuccess = false;
      state.error = false;
    }
  },
  extraReducers: (builder) => {
    /* User Transaction Details */
    builder.addCase(getUserTransactionData.fulfilled, (state, action) => {

      state.isLoading = false;
      state.userTransactionData = action.payload.status === 200 ? action.payload.data : false;
    });
    builder.addCase(getUserTransactionData.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getUserTransactionData.rejected, (state, action) => {
      state.isLoading = false;
      state.userTransactionData = false;
    });

    /* User Deposit */
    builder.addCase(transactionCreate.fulfilled, (state, action) => {
      
      state.isLoading = false;
      state.isSuccess = action.payload.status === 200 ? true : false;
      state.error = action.payload.status !== 200 ? action.payload.data : false;
    });
    builder.addCase(transactionCreate.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(transactionCreate.rejected, (state, action) => {
      state.isLoading = false;
      state.isSuccess = false;
    });

  }
});

export const { setInitialState } = userTransactionSlice.actions;
export default userTransactionSlice.reducer