import React, { useEffect, useState } from "react";
import * as FcIcons from "react-icons/fc";

const Pagination = ({ perPage, total, paginate, currentPage }) => {
  const pageNumbers = [];
  const newPages = [];
  let firstDot = false;
  let lastDot = false;
  const totalPages = Math.ceil(total / perPage);
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  if (currentPage !== 1
    && currentPage !== 2
    && currentPage !== 3
    && currentPage !== totalPages
    && currentPage !== totalPages - 1
    && currentPage !== totalPages - 2
  ) {
    newPages.push(currentPage - 1);
    newPages.push(currentPage);
    newPages.push(currentPage + 1);
  } else if (currentPage === totalPages
    || currentPage === totalPages - 1
    || currentPage === totalPages - 2
  ) {
    newPages.push(totalPages - 4);
    newPages.push(totalPages - 3);
    newPages.push(totalPages - 2);
  } else {
    newPages.push(3);
    newPages.push(4);
    newPages.push(5);
  }

  if (currentPage === 3
    || currentPage === 2
    || currentPage === 1
  ) {
    firstDot = false;
    lastDot = true;
  }
  if (currentPage === totalPages - 2
    || currentPage === totalPages - 1
    || currentPage === totalPages
  ) {
    firstDot = true;
    lastDot = false;
  }

  if (currentPage > 3 && currentPage < totalPages - 2) {
    firstDot = true;
    lastDot = true;
  }
  const [activeId, setActiveId] = useState(currentPage);
  useEffect(() => {
    setActiveId(currentPage);
  }, [currentPage])
  return (
    <nav aria-label="Page navigation" className="pagenav pt-10">
      <ul className="pagination justify-content-end">
        {currentPage !== 1 &&
          <li className="page-item cursor-pointer">
            <span className="page-link prev-btn" aria-label="Previous">
              <span aria-hidden="true"><FcIcons.FcPrevious onClick={() => { paginate(currentPage - 1); setActiveId(currentPage - 1); }} /></span>
            </span>
          </li>
        }
        {pageNumbers.length <= 7 ?
          pageNumbers.map(number => (
            <li key={number} className={"page-item cursor-pointer" + (activeId === number ? " active" : "")} onClick={() => setActiveId(number)} >
              <span className='page-link' onClick={() => paginate(number)}>{number}</span>
            </li>
          ))
          :
          <>
            <li className={"page-item cursor-pointer" + (activeId === 1 ? " active" : "")} onClick={() => setActiveId(1)} >
              <span className='page-link' onClick={() => paginate(1)}>{1}</span>
            </li>
            <li className={"page-item cursor-pointer" + (activeId === 2 ? " active" : "")} onClick={() => setActiveId(2)} >
              <span className='page-link' onClick={() => paginate(2)}>{2}</span>
            </li>
            {firstDot &&
              <li className={"page-item cursor-pointer first"} >
                <span className='page-link' >...</span>
              </li>
            }

            {newPages.map(number => (
              <li key={number} className={"page-item cursor-pointer" + (activeId === number ? " active" : "")} onClick={() => setActiveId(number)} >
                <span className='page-link' onClick={() => paginate(number)}>{number}</span>
              </li>
            ))}
            {lastDot &&
              <li className={"page-item cursor-pointer last"} >
                <span className='page-link' >...</span>
              </li>
            }
            <li className={"page-item cursor-pointer" + (activeId === totalPages - 1 ? " active" : "")} onClick={() => setActiveId(totalPages - 1)} >
              <span className='page-link' onClick={() => paginate(totalPages - 1)}>{totalPages - 1}</span>
            </li>
            <li className={"page-item cursor-pointer" + (activeId === totalPages ? " active" : "")} onClick={() => setActiveId(totalPages)} >
              <span className='page-link' onClick={() => paginate(totalPages)}>{totalPages}</span>
            </li>
          </>
        }
        {currentPage < Math.ceil(total / perPage) &&
          <li className="page-item cursor-pointer">
            <span className="page-link prev-btn" aria-label="Previous">
              <span aria-hidden="true"><FcIcons.FcNext onClick={() => { paginate(currentPage + 1); setActiveId(currentPage + 1); }} /></span>
            </span>
          </li>
        }
      </ul>
    </nav>
  );
};

export default Pagination;
