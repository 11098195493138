import React, { useState } from "react";
import Murga from "images/Murga.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandshake,
  faHome,
  faMoneyBillTransfer,
  faRightFromBracket,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { setLogout } from "redux/Reducers/LoggedSlice";

const AdminHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Logout = () => {
    dispatch(setLogout());
    navigate("/");
  }


  const [burger_class, setBurgerClass] = useState("bar")
  const [menu_class, setMenuClass] = useState("nav-menu-hidden")
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [backgroundClickContainer, setBackgroundClickContainer] = useState('clickNoneContainer')

  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass("cross")
      setMenuClass("nav-menu-visible")

      setIsMenuClicked(true)

      setBackgroundClickContainer("clickDisplayContainer")
    }
    else {
      setBurgerClass("bar")
      setMenuClass("nav-menu-hidden")
      setIsMenuClicked(false)

      setBackgroundClickContainer("clickNoneContainer")
    }
  }


  return (
    <>

      <section className="announcement_bar">
        <marquee text="murga"> Admin Your Playing Hours are between 8am to 9pm</marquee>
      </section>
      <div className={menu_class}>

        <Link to="/admin-dashboard">
          <FontAwesomeIcon icon={faHome} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Dashboard
          </span>
        </Link>
        <Link to="/admin/users">
          <FontAwesomeIcon icon={faUserPen} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Users
          </span>
        </Link>
        <Link to="/admin/bettels">
          <FontAwesomeIcon icon={faHandshake} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Battels
          </span>
        </Link>
        <Link to="/admin/bets">
          <FontAwesomeIcon icon={faMoneyBillTransfer} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Bets
          </span>
        </Link>
        <Link onClick={() => Logout()} >
          <FontAwesomeIcon icon={faRightFromBracket} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Logout
          </span>
        </Link>
      </div>
      <div className="sidebar">

        <div className="murga-logo">
          <Link to="/admin-dashboard"><img src={Murga} className="img-fluid sidebar-logo" alt="logo" />
          </Link>
        </div>


        <Link to="/admin-dashboard" id="user_info_container" ><FontAwesomeIcon icon={faUser} /></Link>


        <div className="hamburger" onClick={updateMenu}>
          <span className={burger_class}></span>
          <span className={burger_class}></span>
          <span className={burger_class}></span>
        </div>

        <div className={backgroundClickContainer} onClick={updateMenu} ></div>


      </div>
    </>
  )
}

export default AdminHeader;