import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, murgaApi } from "lib/api";

const getUserMurgaDetails = createAsyncThunk(
  'admin/user-details/murga',
  async (data) => {
    try {
      const response = await murgaApi.post(`/auth/user-details`, data)
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const getUserUpdate = createAsyncThunk(
  'admin/user-update',
  async (data) => {
    try {
      const response = await murgaApi.post(`/auth/user-update`, data)
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const getUserList = createAsyncThunk(
  'admin/user/list',
  async (data) => {
    try {
      const response = await api.get(`/users?page=${data.page}&per_page=${data.per_page}${data.QST}`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)


const getCsvUser = createAsyncThunk(
  'admin/csv/user/list',
  async (data) => {
    try {
      const response = await api.get(`/users?page=${data.page}&per_page=${data.per_page}${data.QST}`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)


const getUserDetails = createAsyncThunk(
  'admin/user/details',
  async (data) => {
    try {
      const response = await api.get(`/user/${data}`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const agentRegister = createAsyncThunk(
  'auth/direct/agent-register',
  async (data) => {
    try {
      const response = await murgaApi.post(`/auth/agent-register`, data.register)
        .then((value) => {
          const res1 = api.post(`auth/direct`, data.regapi)
            .then((data) => {              
              const fdata = {
                id: value.data.data,
                secret_name: data.data.data.user.secret_name,
              }
              const res2 = murgaApi.post(`/auth/update-agent`, fdata)
                .then((data) => {
                  return { status: 200, data: data.data };
                })
                .catch((err) => {
                  return { status: 400, data: err.response.data };
                });
              return res2;
            })
            .catch((err) => {
              return { status: 400, data: err.response.data };
            });
          return res1;
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)


export {
  getUserMurgaDetails,
  getUserUpdate,
  getUserList,
  getCsvUser,
  getUserDetails,
  agentRegister
};