import { createSlice } from "@reduxjs/toolkit";
import {
    getAgentUserList,
    userChangeStatus,
    getAgentUserListMurga
} from "redux/Actions/AgentUserList";

const AgentUserListSlice = createSlice({
    name: "AgentUserListData",
    initialState: {
        isLoading: false,
        AgentUserListData: false,
        isSuccess: false,
        agentListMurga: {},
        error: {},
    },
    reducers: {
        setInitialState: (state) => {
            state.isSuccess = false;
            state.error = {};
        }
    },
    extraReducers: (builder) => {
        /* User Details */
        builder.addCase(getAgentUserListMurga.fulfilled, (state, action) => {
            state.isLoading = false;
            state.agentListMurga = action.payload.status === 200 ? action.payload.data : {};
        });
        builder.addCase(getAgentUserListMurga.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAgentUserListMurga.rejected, (state, action) => {
            state.isLoading = false;
            state.agentListMurga = {};
        });

        /* User Details */
        builder.addCase(getAgentUserList.fulfilled, (state, action) => {
            state.isLoading = false;
            state.AgentUserListData = action.payload.status === 200 ? action.payload.data : false;
        });
        builder.addCase(getAgentUserList.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getAgentUserList.rejected, (state, action) => {
            state.isLoading = false;
            state.AgentUserListData = false;
        });

        /* User Status Changed */
        builder.addCase(userChangeStatus.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = action.payload.status === 200 ? true : false;
            state.error = action.payload.status !== 200 ? action.payload.data : {};
        });
        builder.addCase(userChangeStatus.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(userChangeStatus.rejected, (state, action) => {
            state.isLoading = false;
            state.isSuccess = false;
        });

    }
});

export const {
    setInitialState
} = AgentUserListSlice.actions


export default AgentUserListSlice.reducer