import React from "react";
import Header from "components/Common/Header";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoutes = ({ component: Component, ...rest }) => {

  const { isLoggedin, userData } = useSelector(state => state.loggedin);
  
  return (    
    isLoggedin &&
      Object.keys(userData).length > 0 &&
      Object.keys(userData.data).length > 0 &&
      Object.keys(userData.data.user).length > 0 &&
      userData.data.user.role_id === 3
      ?
        <>
          <Header />
          <Component {...rest} />
        </>
        : <Navigate to="/" />
  )
}

export default ProtectedRoutes;