import React from "react";
import Router from "Router";
import 'bootstrap/dist/css/bootstrap.min.css';
import "css/Style.css";
import "css/adminStyle.css";
import "css/agentStyle.css";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <Router />
    </>
  );
}

export default App;
