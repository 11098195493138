import React, { useEffect, useState } from "react";
import LoginImage from "images/Login.png";
import Murga from "images/Murga.png";
import Vector1 from "images/Vector1.png";
import { useDispatch, useSelector } from "react-redux";
import { forgetPassword } from "redux/Actions/Authentication";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { setInitialState } from "redux/Reducers/AuthenticationSlice";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { isMessage, error } = useSelector(state => state.authentication);
  const { isLoggedin } = useSelector(state => state.loggedin);
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    dispatch(setInitialState());
  }, [])
  useEffect(() => {
    if (isLoggedin) {
      navigate("/dashboard");
    }
  }, [])

  useEffect(() => {
    if (isMessage && isMessage.message !== '') {
      setDisable(true);
    }
  }, [isMessage])
  const onSubmit = (data) => {
    dispatch(forgetPassword(data));
  }

  return (
    <>
      <section className="background-login forgetpasswordback" style={{ backgroundImage: `url("${LoginImage}")` }}>
        <div className="login_wrapper">
          <div className="logo_wrapper">
            <div className="logo_image"><img src={Murga} className="logo" alt="logo" /> </div>
          </div>
        </div>
        <div className="login_form_header">
          <h2> Forgot Password</h2>
        </div>
        <div className="login_form_wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>

            {isMessage && isMessage.message !== '' &&
              <span className="success d-block" >{isMessage.message}</span>
            }

            <div className="input-container">
              <span className="register_form_icon"> <img src={Vector1} className="icon vector-image" alt="icon1" /></span>
              <input type="text" placeholder="Enter Your Email"
                {...register("email", {
                  onChange: () => {dispatch(setInitialState());},
                  required: "Email should not be empty",
                })} disabled={disable ? true : false} />
            </div>
            {errors.email ? <span className="error d-block">{errors.email.message}</span>
              : error && Object.keys(error).length > 0 && Object.keys(error.data).length > 0
              && error.data.email !== "undefined" &&
              <span className="error d-block" >{error.data.email}</span>}
            <div className="text-center">
              <button type="submit" className="pushable" disabled={disable ? true : false}>
                <span className="front"> Submit </span> </button>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}

export default ForgotPassword;