import { createSlice } from "@reduxjs/toolkit";
import {
  getLogin,
  getRegister,
  forgetPassword,
  updatePassword,
  getsecretName,
} from "redux/Actions/Authentication";

const AuthenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    isLoading: false,
    isLoginError: false,
    isRegsterError: false,
    isSuccess: false,
    isMessage: false,
    error: false,
    userData: false,
    secret: {},
  },
  reducers: {
    setLogin: (state) => {
      state.isSuccess = true;
    },
    setInitialState: (state) => {
      state.error = false;
      state.isMessage = false;
      state.isLoginError = false;
    },
    setInitialRegister: (state) => {
      state.isSuccess = false;
      state.error = false;
    }
  },
  extraReducers: (builder) => {
    
    /* Login */
    builder.addCase(getLogin.fulfilled, (state, action) => {
      // console.log('action.payload',action.payload)
      state.isLoading = false;
      state.isLoginError = action.payload.status !== 200 ? true : false;
      state.isSuccess = action.payload.status === 200 ? true : false;
      state.error = action.payload.status !== 200 ? action.payload.data : false;
      state.userData = action.payload.status === 200 ? action.payload.data : false;
    });
    builder.addCase(getLogin.pending, (state, action) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.userData = false;
      state.error = false;
    });
    builder.addCase(getLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.isLoginError = false;
      state.isSuccess = false;
      state.userData = false;
    });

    /* secretName */
    builder.addCase(getsecretName.fulfilled, (state, action) => {
      // console.log('action.payload',action.payload)
      state.isLoading = false;
      state.secret = action.payload.status === 200 ? action.payload.data : {};
    });
    builder.addCase(getsecretName.pending, (state, action) => {
      state.isLoading = true;
      state.secret = {};
    });
    builder.addCase(getsecretName.rejected, (state, action) => {
      state.isLoading = false;
      state.secret = {};
    });

    /* Register */
    builder.addCase(getRegister.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isRegsterError = action.payload.status !== 200 ? true : false;
      state.isSuccess = action.payload.status === 200 ? true : false;
      state.userData = action.payload.status === 200 ? action.payload.data : false;
      state.error = action.payload.status !== 200 ? action.payload.data : false;
    });
    builder.addCase(getRegister.pending, (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.userData = false;
    });
    builder.addCase(getRegister.rejected, (state, action) => {
      state.isLoading = false;
      state.userData = false;
    });


    /* Forget Password */
    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isMessage = action.payload.status === 200 ? action.payload.data : false;
      state.error = action.payload.status !== 200 ? action.payload.data : false;
    });
    builder.addCase(forgetPassword.pending, (state, action) => {
      state.isLoading = true;
      state.isMessage = false;
      state.error = false;
    });
    builder.addCase(forgetPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.isMessage = false;
      state.error = false;
    });

    /* Update Password */
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isMessage = action.payload.status === 200 ? action.payload.data : false;
      state.error = action.payload.status !== 200 ? action.payload.data : false;
    });
    builder.addCase(updatePassword.pending, (state, action) => {
      state.isLoading = true;
      state.isMessage = false;
      state.error = false;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.isLoading = false;
      state.isMessage = false;
      state.error = false;
    });
  }
});

export const {
  setLogin,
  setInitialState,
  setInitialRegister } = AuthenticationSlice.actions;
export default AuthenticationSlice.reducer