import React, { useEffect, useState } from "react";
import LoginImage from "images/Login.png";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "components/Pagination";
import {
  walletTransactions,
  walletTransactionsApproval
} from "redux/Actions/AgentDashboard"
import { setInitialState } from "redux/Reducers/AgentDashbaordSlice";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import { useParams } from "react-router-dom";

const AgentWallet = () => {
  const dispatch = useDispatch();
  const { source, username } = useParams();
  const { transactionsData, isSuccess, error } = useSelector(state => state.AgentDashbaordSlice);
  const [search, setSearch] = useState('');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(0);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        toast.success(`Status changed successfully`, {
          toastId: 'success',
          transition: Zoom
        });
        dispatch(setInitialState());
        let QST = `${search !== '' ? `&search=${search}` : ''}${role !== '' ? `&role=${role}` : ''}${status !== '' ? `&source=${status}` : ''}`;
        dispatch(walletTransactions({
          page: currentPage,
          per_page: 10,
          QST:QST
        }));
      }, 2000);
    }
    if (Object.keys(error).length > 0
      && typeof error.msg !== 'undefined') {
      setTimeout(() => {
        toast.error(error.msg, {
          toastId: 'success',
          transition: Zoom
        });
        dispatch(setInitialState());
      }, 2000);
    }
  }, [isSuccess, error])

  // const [transaction, setTransaction] = useState([]);
  useEffect(() => {
    if (Object.keys(transactionsData).length > 0
      && Object.keys(transactionsData.data).length) {
      // setTransaction([...transactionsData.data])
      if (transactionsData.pagination.current_page === 1) {
        setTotal(transactionsData.pagination.total);
        setPerPage(transactionsData.pagination.per_page);
      }
    }
  }, [transactionsData]);

  useEffect(() => {
    if (typeof source !== 'undefined') {
      setStatus(source);
    }
    if (typeof username !== 'undefined') {
      setSearch(username);
    }
    
    let QST = `${typeof username !== 'undefined' ? `&search=${username}` : search !== '' ? `&search=${search}` : ''}${role !== '' ? `&role=${role}` : ''}${typeof source !== 'undefined' ? `&source=${source}` : status !== '' ? `&source=${status}` : ''}`;
    dispatch(walletTransactions({
      page: currentPage,
      per_page: 10,
      QST: QST
    }));
  }, [currentPage, source, username]);
  const handleOnchange = (e, id) => {
    dispatch(walletTransactionsApproval({
      id: id,
      status: e.target.value
    }));
  }
  const handleFilter = () => {
    let QST = `${search !== '' ? `&search=${search}` : ''}${role !== '' ? `&role=${role}` : ''}${status !== '' ? `&source=${status}` : ''}`;
    dispatch(walletTransactions({
      page: 1,
      per_page: 10,
      QST: QST
    }));
  }
  // console.log('transactionsData',transaction);
  return (
    <>
      <ToastContainer transition={Zoom} position={toast.POSITION.TOP_CENTER} />
      <section className="agentPlayer_dashboard active-bg" >
        <div className="container-fluid">
          <div className="row">
            <h1 className="active-caption">Wallet</h1>
            <div className="col-md-12">
              <div className="filtter_wrapper">
                <div className="row">
                  <div className="col-md-4">
                    <input type="text" className="search"
                      placeholder="Username, Email , Name"
                      id="agent_player_search"
                      onChange={(e) => setSearch(e.target.value)} />
                  </div>
                  <div className="col-md-3">
                    <select
                      onChange={(e) => setStatus(e.target.value)}
                      value={status}
                      id="requesttype"
                      className="flex-1 px-3 py-2 border dropdown_role_id placeholder-gray-400" name="requesttype">
                      <option value={""}>Select option</option>
                      <option value={'Cash-In'}>Cash In</option>
                      <option value={'Cash-Out'}>Cash Out</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <select id="status"
                      className="flex-1 px-3 py-2 border placeholder-gray-400 dropdown_role_id"
                      name="status"
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value="">- Status -</option>
                      <option value="Completed">Completed</option>
                      <option value="Pending">Pending</option>
                      <option value="Cancelled">Cancelled</option>
                    </select>
                  </div>
                  <div className="col-md-2">
                    <button className="flex-2 px-6 py-2 text-sm text-white apply_filter_button"
                      onClick={() => handleFilter()}> Apply Filters </button>
                  </div>

                </div>
              </div>
              <div className="table_wrapper">
                <div className="table_background">
                  <table>
                    <tbody>
                      <tr>
                        <th>S.No</th>
                        <th>Type</th>
                        <th>Customer</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Transaction ID</th>
                        <th>Status</th>
                      </tr>
                      {Object.keys(transactionsData).length > 0
                        && transactionsData.data.length > 0
                        && transactionsData.data.map((item, index) => {
                          return (
                            <tr key={`tr${index}`}>
                              <td data-cell="s.no">{(currentPage * perPage) + index + 1 - perPage}</td>
                              <td data-cell="type" className="admin_deposit-green">
                                {item?.source === 'Cash-In' ? 'Deposit ' : 'Withdrawl'}
                              </td>
                              <td data-cell="customer">{item?.owner_username}</td>
                              <td data-cell="date">{item?.created_at}</td>
                              <td data-cell="amount">$ {parseFloat(item.amount).toFixed(2)} </td>
                              <td data-cell="transaction id">MT-000{item.id}</td>
                              <td data-cell="status" className="bold-text">
                                {item.status === 'Pending' ?
                                  <div className="activateaccount">
                                    <select
                                      defaultValue={item.status}
                                      onChange={(e) => handleOnchange(e, item.id)}
                                    >
                                      <option value={`Completed`}>Completed</option>
                                      <option value={`Pending`}>Pending</option>
                                      <option value={`Cancelled`}>Cancelled</option>
                                    </select>
                                  </div>
                                  :
                                  item.status
                                }
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                  {(total > perPage) && (
                    <div className="setPagination pb-1 pe-4 pt-3">
                      <Pagination
                        perPage={perPage}
                        total={total}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AgentWallet;