import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, murgaApi } from "lib/api";


const agentDashboardData = createAsyncThunk(
  'agentDashboard',
  async () => {
    try {
      const response = await api.get(`dashboard/agent`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const walletTransactions = createAsyncThunk(
  'wallet-transactions',
  async (data) => {
    try {
      const response = await api.get(`wallet-transactions?page=${data.page}&per_page=${data.per_page}${data.QST}`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const walletTransactionsApproval = createAsyncThunk(
  'wallet-transaction/approval',
  async (data) => {
    try {
      const response = await api.patch(`wallet-transaction/approval`, data, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)


const getagentData = createAsyncThunk(
  'getAgentData',
  async (data) => {
    try {
      const response = await murgaApi.post(`/auth/agent-details`, data)
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)


export {
  agentDashboardData,
  walletTransactions,
  walletTransactionsApproval,
  getagentData
};