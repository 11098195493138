import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboard } from "redux/Actions/Admin/Dashboard";
import bi_cash_out_coin  from "images/bi_cash-out-coin.png";
import bi_cash_in_coin  from "images/bi_cash-in-coin.png";

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const { dashboardData } = useSelector(state => state.adminDashboard);
  useEffect(() => {
    dispatch(getDashboard());
  }, [])

  // console.log(dashboardData);

  return (
    <>
      <section className="active-bg" id="agent_dashboard">
        <div className="row">
          <div className="col-12 col-md-12 col-md-12">
            <div className="container-fluid">
              <div className="row">
                <div className="admin_dash_wrapper">
                  <div className="ad-cards">
                    <div className="flex gap-4">
                      <div className="col">
                        <div className="ad-dash-icon-box">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="text-light">{dashboardData?.data?.total_online}</h3>
                              <p className="text-light">Online User</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="ad-dash-icon-box">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="text-light">{dashboardData?.data?.user_activated}</h3>
                              <p className="text-light">Activated Account</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="ad-dash-icon-box">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="text-light">{dashboardData?.data?.user_pending}</h3>
                              <p className="text-light">Pending Account</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="ad-dash-icon-box">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="text-light">{dashboardData?.data?.totalWins}</h3>
                              <p className="text-light">Total Best Wins</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="ad-dash-icon-box">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="text-light">{dashboardData?.data?.totalLoss}</h3>
                              <p className="text-light">Total best Loss</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="row">
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                        <div className="ad-dash-icon-box-lavender">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="text-light">{dashboardData?.data?.total_count_battle_cancelled}</h3>
                              <p className="text-light">Total Fight Cancelled</p>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                        <div className="ad-dash-icon-box-green">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="text-light">{dashboardData?.data?.total_count_battle_reverted}</h3>
                              <p className="text-light">Total Fight Reverted</p>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                        <div className="ad-dash-icon-box-red">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="text-light">{dashboardData?.data?.total_count_battle_closed}</h3>
                              <p className="text-light">Total Fight Closed</p>
                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-3 col-md-3 col-lg-3">
                        <div className="ad-dash-icon-box-yellow">
                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="text-light">{dashboardData?.data?.total_count_battle_pending}</h3>
                              <p className="text-light">Total Fight Pending</p>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="ad-cards">

                    <div className="row">

                      <div className="col-12 col-md-4 col-lg-4 ad-dash-mid-icon-box">
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-12">
                            <div className="row">
                              <div className="col-12 text-center">
                                <div className="margin-auto">
                                  <h1 className="text-dark">{dashboardData?.data?.total_count_battle}</h1>
                                  <p className="text-dark">Total Bets</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-8 col-md-8">
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-mid-icon-box1">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-dark">{dashboardData?.data?.total_count_battle_meron}</h2>
                                  <p className="text-dark">Total Red Bets</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-mid-icon-box2">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-dark">{dashboardData?.data?.total_count_battle_wala}</h2>
                                  <p className="text-dark">Total Blue Bets</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="ad-dash-mid-icon-box3">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-dark">{dashboardData?.data?.totalLoss}</h2>
                                  <p className="text-dark">Total best Loss</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>


                  <div className="mid_ad-cards-bg_">
                    <div className="row">
                      <div className="col">
                        <div className="ad-dash-icon-box-left-border">
                          <div className="row">
                            <div className="col-12">
                              <p className="text-light">Total Fight Cancelled</p>
                              <h3 className="text-light">{dashboardData?.data?.total_count_battle_cancelled}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="ad-dash-icon-box-left-border">
                          <div className="row">
                            <div className="col-12">
                              <p className="text-light">Total Fight Reverted</p>
                              <h3 className="text-light">{dashboardData?.data?.total_count_battle_reverted}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="ad-dash-icon-box-left-border">
                          <div className="row">
                            <div className="col-12">
                              <p className="text-light">Total Fight Closed</p>
                              <h3 className="text-light">{dashboardData?.data?.total_count_battle_closed}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="ad-dash-icon-box-left-border">
                          <div className="row">
                            <div className="col-12">
                              <p className="text-light">Total Fight Pending</p>
                              <h3 className="text-light">{dashboardData?.data?.total_count_battle_pending}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </div>


                  <div className="ad_all_users">
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="ad_dash_blue_bg">
                          <div className="d-flex">
                            <img src={bi_cash_in_coin} className="img-fluid" alt="icon" />
                            <p className="text-light fs-4">Cash In</p>
                          </div>
                          <h1 className="text-light">{parseFloat(dashboardData?.data?.cash_in).toFixed(0)}</h1>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                        <div className="ad_dash_blue_bg">
                          <div className="d-flex">
                            <img src={bi_cash_out_coin} className="img-fluid" alt="icon" />
                            <p className="text-light fs-4">Cash out</p>
                          </div>
                          <h1 className="text-light">{parseFloat(dashboardData?.data?.cash_out).toFixed(0)}</h1>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="ad-cards">

                    <div className="row">

                      <div className="col-12 col-md-4 col-lg-4 ad-dash-yellow-bottom-icon-box">
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-12">
                            <div className="">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <div className="margin-auto-mx">
                                    <p className="text-light fs-5">Total Wallet</p>
                                    <h1 className="text-light">{parseFloat(dashboardData?.data?.wallet).toFixed(2)}</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-8 col-lg-8">
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.current_sa_commission).toFixed(2)}</h2>
                                  <p className="text-light">Operator Wallet</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.pa_wallet).toFixed(2)}</h2>
                                  <p className="text-light">Platinum Agent Wallet</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.ma_wallet).toFixed(2)}</h2>
                                  <p className="text-light">Master Agent Wallet</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.ga_wallet).toFixed(2)}</h2>
                                  <p className="text-light">Gold Agent Wallet</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.pl_wallet).toFixed(2)}</h2>
                                  <p className="text-light">Player Wallet</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className="ad-cards">

                    <div className="row">

                      <div className="col-12 col-md-4 col-lg-4 ad-dash-red-bottom-icon-box">
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-12">
                            <div className="">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <div className="margin-auto">
                                    <p className="text-light fs-5">Commission Wallet</p>
                                    <h1 className="text-light">{parseFloat(dashboardData?.data?.commission_wallet).toFixed(2)}</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-8 col-lg-8">
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.pa_com_wallet).toFixed(2)}</h2>
                                  <p className="text-light">Platinum Agent Commission Wallet</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.ma_com_wallet).toFixed(2)}</h2>
                                  <p className="text-light">Master Agent Commission Wallet</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.ga_com_wallet).toFixed(2)}</h2>
                                  <p className="text-light">Gold Agent Commission Wallet</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="ad-cards">

                    <div className="row">

                      <div className="col-12 col-md-4 col-lg-4 ad-dash-blue-bottom-icon-box">
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-12">
                            <div className="">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <div className="margin-auto">
                                    <p className="text-light fs-5">Commission</p>
                                    <h1 className="text-light">{parseFloat(dashboardData?.data?.total_commission).toFixed(2)}</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-8 col-lg-8">
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.super_commission).toFixed(2)}</h2>
                                  <p className="text-light">Total Operator Commission</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.platinum_commission).toFixed(2)}</h2>
                                  <p className="text-light">Total Platinum Agent Commission</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.master_commission).toFixed(2)}</h2>
                                  <p className="text-light">Total Master Agent Commission</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 col-lg-6">
                            <div className="ad-dash-bottom-icon-box">
                              <div className="row">
                                <div className="col-12 text-center">
                                  <h2 className="text-light">{parseFloat(dashboardData?.data?.gold_commission).toFixed(2)}</h2>
                                  <p className="text-light">Total Gold Agent Commission</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AdminDashboard;