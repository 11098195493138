import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
    const styles = {
        notmatch: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh"
        },
        section: {
            fontFamily: "Montserrat",
            fontSize: "1rem",
            lineHeight: 1.5,
            color: "#292b2c",
            padding: "0 2em",
            maxWidth: "50%",
            width: "100%",
            margin: "0 auto",
            height: "400px",
            textAlign: "center",
        },
        wrapper: {
            textAlign: "center",
            backgroundColor: "#fff",
            border: "1px solid #009de4",
            borderRadius: "8px",
            padding: "40px 25px",
        },
        name: {
            marginBottom: "0",
            fontWeight: 600,
            fontSize: "1rem"
        },
        position: { fontWeight: 400, fontFamily: "Montserrat",fontSize: "20px",},
        title: {
            fontSize:"26px",
            margin:"0 0 10px",
            textTransform: "capitalize",
        },
        notfound:{
            color: "#000",
            fontSize: "114px",
            lineHeight: "1.4",
            fontFamily: "Montserrat",
            margin: "0px auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        iconCss:{
            width:"100%",
            height:"100%",
        },
        red: {
            color:"#f00"
        },
        homebtn: {
            background: "#000",
            color:"#fff",
            padding: "10px 30px",
            borderRadius: "6px",
        },
        imglogo:{
            paddingBottom: "30px",
            width: "30%",  
        },     
};

    return (
        <>
            <div style={styles.notmatch}>
                <section style={styles.section} className="notFoundpage">                    
                    <div style={styles.wrapper} className="item-box-pageNotfound">                    
                        <div style={styles.name}>
                            <div className="item-pageNotfound" style={styles.notfound} >404.</div>
                            <h3 style={styles.title} ><span style={styles.red}>Opps!</span> Page not found.</h3>
                            <p style={styles.position}>The page you're looking for doesn't exist.</p>
                            <Link style={styles.homebtn} to="/">Go to home</Link>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default NotFound;