import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "lib/api";


const getUserTransactionData = createAsyncThunk(
  'walletallet-transactin',
  async (data) => {
    try {
      const response = await api.get(`wallet-transactions?${data}&filter_by=my`, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        },
      })
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)


const transactionCreate = createAsyncThunk(
  'wallet-transaction/create',
  async (data) => {
    try {
      const response = await api.post(`wallet-transaction/create`, data, {
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token")
        }
      })
        .then((value) => {
          console.log(value);
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          if (err.response.status === 403) {
            return { status: 403, data: err.response.data };
          }
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

export {
  getUserTransactionData,
  transactionCreate
};