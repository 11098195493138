import React, { useEffect, useState, useRef } from "react";
import LoginImage from "images/Login.png";
import Murga from "images/Murga.png";
import Vector2 from "images/Vector2.png";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updatePassword } from "redux/Actions/Authentication";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { setInitialState } from "redux/Reducers/AuthenticationSlice";

const UpdatePassword = () => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const { isMessage } = useSelector(state => state.authentication);
  const [disable, setDisable] = useState(false);
  const { isLoggedin } = useSelector(state => state.loggedin);
  useEffect(() => {
    if (isLoggedin) {
      navigate("/dashboard");
    }
  }, [])
  useEffect(() => {
    dispatch(setInitialState());
  }, [])
  const password = useRef({});
  password.current = watch("password", "");
  useEffect(() => {
    if (isMessage && isMessage.message !== '') {
      setDisable(true);
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, [isMessage])
  const onSubmit = (data) => {
    data.token = token;
    dispatch(updatePassword(data));
  }

  return (
    <>
      <section className="background-login" style={{ backgroundImage: `url("${LoginImage}")` }}>
        <div className="login_wrapper">
          <div className="logo_wrapper">
            <div className="logo_image"><img src={Murga} className="logo" alt="logo" /> </div>
          </div>
        </div>
        <div className="login_form_header">
          <h2> Update Password</h2>
        </div>
        <div className="login_form_wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>

            {isMessage && isMessage.message !== '' &&
              <span className="success d-block" >{isMessage.message}</span>
            }

            <div className={`input-container` + (errors.password ? " error" : "")}>
              <span className="register_form_icon"> <img src={Vector2} className="icon vector-image" alt="icon1" /></span>
              <input type="password" placeholder="Enter new password"
                {...register("password", {
                  onChange: () => {dispatch(setInitialState());},
                  required: "Password should not be empty",
                })} disabled={disable ? true : false} />
            </div>
            {errors.password && <span className="error d-block">{errors.password.message}</span>}


            <div className={`input-container` + (errors.cpassword ? " error" : "")}>
              <span className="register_form_icon"> <img src={Vector2} className="icon vector-image" alt="icon1" /></span>
              <input type="password" placeholder="Enter new password"
                {...register("cpassword", {
                  onChange: () => {dispatch(setInitialState());},
                  required: "Confirm Passward should not be empty",
                  validate: value => value === password.current || "The passwords do not match."
                })} disabled={disable ? true : false} />
            </div>
            {errors.cpassword && <span className="error d-block">{errors.cpassword.message}</span>}


            <div className="text-center">
              <button type="submit" className="pushable" disabled={disable ? true : false}>
                <span className="front"> Submit </span> </button>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}

export default UpdatePassword;