import React, { useEffect, useState } from "react";
import LoginImage from "images/Login.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getUserTransactionData } from "redux/Actions/UserTransaction";
import { Link } from "react-router-dom";
import Pagination from "components/Pagination";

const Transaction = () => {

  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.loggedin);
  const { userTransactionData } = useSelector(state => state.userTransactionData);

  const [userid, setUserId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (Object.keys(userTransactionData).length > 0
      && Object.keys(userTransactionData.pagination).length
      && userTransactionData.pagination.current_page === 1
    ) {
      setTotal(userTransactionData.pagination.total);
      setPerPage(userTransactionData.pagination.per_page);
    }
  }, [userTransactionData]);

  useEffect(() => {
    const data = `player_id=${userid}&page=${currentPage}&per_page=${perPage}`;
    dispatch(getUserTransactionData(data))
  }, [currentPage]);

  useEffect(() => {
    const data = `player_id=${userid}&page=1&per_page=${perPage}`;
    dispatch(getUserTransactionData(data))
  }, [perPage]);


  useEffect(() => {
    const userid = userData.data.user.id;
    setUserId(userid)
    const data = `player_id=${userid}&page=${currentPage}&per_page=12`;
    dispatch(getUserTransactionData(data));

  }, [dispatch, userData])


  return (
    <>
      <section className="dashboard-bg-image transaction_type_dasffs_sdf" style={{ backgroundImage: `url("${LoginImage}")` }}>
        <div className="container-fluid">
          <div className="">
            <h1 className="transaction-heading text-center">Transaction History</h1>
            <div className="transaction_wrapper">
              <div className="transaction_table_wrapper">
                <div className="transaction_table_top_fields">
                  <div className="row">
                    <div className="col-6 col-md-6 col-lg-6">
                      <div className="d-flex">
                        <p className="transaction-table-p">Show</p>
                        <select onChange={(e) => setPerPage(e.target.value)}>
                          <option value={12}>12</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                        </select>
                        <p className="transaction-table-p">Entries</p>
                      </div>
                    </div>
                    <div className="col-md-6 text-right">
                      <div className="funds_transaction_wrapper">
                        <div className="add_fund_button">
                          <Link to="/user-deposit">Add Funds</Link>
                        </div>
                        <div className="add_fund_button widrabutton">
                          <Link to="/user-withdrawal">Withdrawal funds</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="transaction_table_fields">
                  <table>
                    <thead>
                      <tr>
                        <th>Index</th>
                        <th>Transaction ID</th>
                        <th>Transaction Type</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Status</th>
                        <th>Agent Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        typeof userTransactionData !== 'undefined'
                        && Object.keys(userTransactionData).length > 0
                        && Object.keys(userTransactionData.data).length
                        && userTransactionData.data.map((item, index) => {
                          return (
                            <tr key={`t${index}`}>
                              <td data-cell="Index" data-label="Index">{(currentPage * perPage) + index + 1 - perPage}</td>
                              <td data-cell="Transaction ID" data-label="Transaction ID">MF{item.id}</td>
                              <td data-cell="Transaction Type" data-label="Transaction Type">{item.source}</td>
                              <td data-cell="Amount" data-label="Amount">{item.amount}</td>
                              <td data-cell="Date" data-label="Date">{item.created_at}</td>
                              <td data-cell="Status" data-label="Status">{item.status}</td>
                              <td data-cell="Agent Name" data-label="Agent Name">{item.sponsor_full_name}</td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>

                <div className="transaction-container-bar-next">
                  {(total > perPage) && (
                    <div className="setPagination pb-1 pe-4 pt-3">
                      <Pagination
                        perPage={perPage}
                        total={total}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </div>
                  )}
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Transaction;