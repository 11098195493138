import { createAsyncThunk } from "@reduxjs/toolkit";
import { api, murgaApi } from "lib/api";

const getLogin = createAsyncThunk(
  'getLogin',
  async (data) => {

    try {
      const response = await murgaApi.post(`auth/login`, data.login)
        .then((value) => {
          let apireq = data.loginapi;
          let auth = 'direct';
          if (value.data.data.role === 1
            || value.data.data.role === 5
            || value.data.data.role === 6) {
            apireq = data.agentapi;
            // if(value.data.data.role === 6){
            //   apireq = {
            //     username: `${value.data.data.username}-3`,
            //     password: data.agentapi.password,
            //     source: data.agentapi.source,
            //     uuid: data.agentapi.uuid,
            //   }
            // }
            auth = 'login';
          } else {
            if (typeof value.data.data.secret_name !== 'undefined'
              && value.data.data.secret_name !== 'undefined'
              && value.data.data.secret_name !== ''
              && typeof value.data.data.hash !== 'undefined'
              && value.data.data.hash !== 'undefined'
              && value.data.data.hash !== ''
            ) {
              apireq = {
                foreign_id: value.data.data.username,
                secret_name: value.data.data.secret_name,
                wallet: 0,
                role_id: 3,
                hashed: value.data.data.hash
              };
            }
          }
          const res1 = api.post(`auth/${auth}`, apireq)
            .then((data) => {
              return { status: 200, data: data.data };
            })
            .catch((err) => {
              return {
                status: 400, data: { data: err.response.data.errors.secret_name[0] }
              };
            });
          return res1;
          // return { status: 200, data: value.data };
        })
        .catch((err) => {
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)


const getsecretName = createAsyncThunk(
  'auth/agent-refrel',
  async (data) => {
    try {
      const response = await murgaApi.post(`auth/agent-refrel`, data)
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const getRegister = createAsyncThunk(
  'getRegister',
  async (data) => {
    try {
      const response = await murgaApi.post(`auth/register`, data.register)
        .then((value) => {
          const res1 = api.post(`auth/direct`, data.loginapi)
            .then((data) => {
              return { status: 200, data: data.data };
            })
            .catch((err) => {
              return { status: 400, data: err.response.data };
            });
          return res1;
        })
        .catch((err) => {
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const forgetPassword = createAsyncThunk(
  'forgetPassword',
  async (data) => {
    try {
      const response = await murgaApi.post(`auth/forgot-password`, data)
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)

const updatePassword = createAsyncThunk(
  'updatePassword',
  async (data) => {
    try {
      const response = await murgaApi.post(`auth/update-password`, data)
        .then((value) => {
          return { status: 200, data: value.data };
        })
        .catch((err) => {
          return { status: 400, data: err.response.data };
        });
      return response;
    } catch (err) {
      return {
        status: 400,
        error: err.message
      };
    }
  }
)


export {
  getLogin,
  getRegister,
  forgetPassword,
  updatePassword,
  getsecretName
};