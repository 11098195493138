import { configureStore } from "@reduxjs/toolkit";
import AuthenticationReducer from "./Reducers/AuthenticationSlice";
import BattlesSlice from "./Reducers/BattlesSlice";
import LoggedSlice from "./Reducers/LoggedSlice";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import UserprofileSlice from "./Reducers/UserprofileSlice";
import UserTransactionSlice from "./Reducers/UserTransactionSlice";
import UserGameHistorySlice from "./Reducers/UserGameHistorySlice";
import UserBetHistorySlice from "./Reducers/UserBetHistorySlice";
import AgentUserListSlice from "./Reducers/AgentUserListSlice";
import AgentDashbaordSlice from "./Reducers/AgentDashbaordSlice";
import DashboardSlice from "./Reducers/Admin/DashboardSlice";
import AdminUserSlice from "./Reducers/Admin/UserSlice";
import BattleUserSlice from "./Reducers/Admin/BattlesSlice";
import BetsSlice from "./Reducers/Admin/BetsSlice";

const appReducers = combineReducers({
  authentication: AuthenticationReducer,
  loggedin: LoggedSlice,
  battle: BattlesSlice,
  userProfileData: UserprofileSlice,
  userTransactionData: UserTransactionSlice,
  userGameHistoryData: UserGameHistorySlice,
  userBetHistoryData: UserBetHistorySlice,
  AgentUserListData: AgentUserListSlice,
  AgentDashbaordSlice: AgentDashbaordSlice,
  adminDashboard: DashboardSlice,
  adminUser: AdminUserSlice,
  adminBattle: BattleUserSlice,
  adminBet: BetsSlice,
});

const reducers = (state, action) => {

  if (typeof action !== "undefined" &&
    typeof action.payload !== "undefined"
  ) {
    if (action.payload.status === 403) {
      storage.removeItem("token");
      storage.removeItem("persist:rootlogin");
      return appReducers(undefined, action);
    }
  }

  if (
    typeof action !== "undefined" &&
    action.type === "loggedIn/setLogout"
  ) {
    storage.removeItem("token");
    storage.removeItem("persist:rootlogin");
    return appReducers(undefined, action);
  }
  return appReducers(state, action);
};

const persistConfig = {
  key: 'rootlogin',
  storage,
  whitelist: [
    'loggedin',
    'battle',
    'userProfileData',
    'userTransactionData',
    'userGameHistoryData',
    'userBetHistoryData',
    'AgentUserListData',
    'AgentDashbaordSlice',
    'adminDashboard',
    'adminUser',
    'adminBattle',
    'adminBet',
  ],
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

let persistor = persistStore(store);

const reset_store = async () => {
  const persistConfig = {
    key: "rootlogin",
    storage,
    blacklist: [
      "authorized",
    ],
    stateReconciler: autoMergeLevel2,
  };
  const persistedReducer = persistReducer(persistConfig, reducers);
  const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
  });
  persistStore(store);
};

export { store, persistor, reset_store, reducers };

// export { store, persistor };
// export default store;
