import React from "react";

const UserList = () => {

  return (
    <>
    <section className="admin-dashboard">   
      <div className="dashboard">        
        <div className="adminmain-content">
          
        </div>   
      </div>
    </section> 
    </>
  ) 
}

export default UserList;