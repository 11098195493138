import { createSlice } from "@reduxjs/toolkit";
import { getUserBetHistoryData } from "redux/Actions/UserBetHistory";

const userBetSlice = createSlice({
    name: "userBetHistoryData",
    initialState: {
        isLoading: false,
        userBetHistoryData: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        /* User Details */
        builder.addCase(getUserBetHistoryData.fulfilled, (state, action) => {
            state.isLoading = false;
            state.userBetHistoryData = action.payload.status === 200 ? action.payload.data : false;
        });
        builder.addCase(getUserBetHistoryData.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getUserBetHistoryData.rejected, (state, action) => {
            state.isLoading = false;
            state.userBetHistoryData = false;
        });

    }
});

export default userBetSlice.reducer