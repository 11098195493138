import React, { useEffect, useState } from "react";
import LoginImage from "images/Login.png";
import { useSelector, useDispatch } from "react-redux";
import { getUserGaneHistoryData } from "redux/Actions/UserGameHistory";
import Pagination from "components/Pagination";

const GameHistory = () => {

  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.loggedin);
  const { userGameHistoryData } = useSelector(state => state.userGameHistoryData);

  const [userid, setUserId] = useState(0);  
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (Object.keys(userGameHistoryData).length > 0
      && Object.keys(userGameHistoryData.pagination).length
      && userGameHistoryData.pagination.current_page === 1
    ) {
      setTotal(userGameHistoryData.pagination.total);
      setPerPage(userGameHistoryData.pagination.per_page);
    }
  }, [userGameHistoryData]);
  // console.log('userGameHistoryData',userGameHistoryData)
  useEffect(() => {
    const data = `player_id=${userid}&page=${currentPage}&per_page=${perPage}`;
    dispatch(getUserGaneHistoryData(data))
  }, [currentPage]);

  useEffect(() => {
    const data = `player_id=${userid}&page=1&per_page=${perPage}`;
    dispatch(getUserGaneHistoryData(data))
  }, [perPage]);

  useEffect(() => {
    const userid = userData.data.user.id;
    setUserId(userid)
    const data = `player_id=${userid}&page=${currentPage}&per_page=12`;
    dispatch(getUserGaneHistoryData(data))
  }, [dispatch, userData])


  return (
    <>
      <section className="dashboard-bg-image gamehistorydsa" style={{ backgroundImage: `url("${LoginImage}")` }}>
        <div className="container-fluid ">
          <div className="gamehistoryfasfas">
            <h1 className="transaction-heading text-center">Game History</h1>
            <div className="transaction_wrapper">
              <div className="transaction_table_wrapper">
                <div className="transaction_table_top_fields">
                  <div className="row">
                    <div className="col-6 col-md-6 col-lg-6">
                      <div className="d-flex">
                        <p className="transaction-table-p">Show</p>
                        <select onChange={(e) => setPerPage(e.target.value)}>
                          <option value={12}>12</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                        </select>
                        <p className="transaction-table-p">Entries</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="transaction_table_fields">
                  <table className="gameHistoryTable">
                    <thead>
                      <tr>
                        <th data-cell="S.No" data-label="S.No" className="gameHistorySerialHide">S.No</th>
                        <th>Fight Name</th>
                        <th>Fight Number</th>
                        <th>Total Bets</th>
                        <th>Payout</th>
                        <th>Game Duration</th>
                        <th>Manager</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        typeof userGameHistoryData !== 'undefined'
                        && Object.keys(userGameHistoryData).length > 0
                        && Object.keys(userGameHistoryData.data).length
                        && userGameHistoryData.data.map((item, index) => {
                          return (
                            <tr key={`sa${index}`}>
                              <td data-cell="Index" data-label="Index" className="gameHistorySerialHide" >
                              {(currentPage * perPage) + index + 1 - perPage}</td>
                              <td data-cell="Name" data-label="Name">{item.name}</td>
                              <td data-cell="FightNumber" data-label="FightNumber">{item.fight_number}</td>
                              <td data-cell="TotalBet" data-label="TotalBet">{item.total_bet}</td>
                              <td data-cell="Payout" data-label="Payout">{item.payout}</td>
                              <td data-cell="GameDuration" data-label="GameDuration">{item.status}</td>
                              <td data-cell="Manager" data-label="Manager">{item.created_by_full_name}</td>
                              <td data-cell="Status" data-label="Status">{item.status}</td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="transaction-container-bar-next">
                  {(total > perPage) && (
                    <div className="setPagination pb-1 pe-4 pt-3">
                      <Pagination
                        perPage={perPage}
                        total={total}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default GameHistory;