import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PublicRoutes from "router/PublicRoutes";
import ProtectedRoutes from "router/ProtectedRoutes";
import ProtectedRoutesAdmin from "router/ProtectedRoutesAdmin";
import ProtectedRoutesAgent from "router/ProtectedRoutesAgent";
import NotFound from 'components/NotFound';
import Usertransaction from 'components/Dashboard/Transaction';
import UserDashboard from 'components/Dashboard/Dashboard';
import Userhome from 'components/Dashboard/Userhome';
import Login from 'components/Auth/Login';
import ForgotPassword from 'components/Auth/ForgotPassword';
import UpdatePassword from 'components/Auth/UpdatePassword';
import Register from "components/Auth/Register";
import Userprofile from "components/Dashboard/Userprofile";
import BetHistory from "components/Dashboard/BetHistory";
import GameHistory from "components/Dashboard/GameHistory";
import UserDeposit from "components/Dashboard/UserDeposit";
import UserWithdrawal from "components/Dashboard/UserWithdrawal";
import AdminDashboard from "admin/AdminDashboard";
import UserList from "admin/UserList";
import AgentDashboard from "agent/AgentDashboard";
import TestApi from "components/TestApi";
import AgentPlayer from "components/Agents/AgentPlayers";
import AgentWallet from "components/Agents/AgentWallet";
import AgentLogs from "components/Agents/AgentLogs";
import Users from "components/Admin/Users";
import Bets from "components/Admin/Bets";
import Bettels from "components/Admin/Bettels";
import UserDetails from "components/Admin/UserDetails";
import SingleBattel from "components/Admin/SingleBattel";
import AddNewUser from "components/Admin/AddNewUser";


const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PublicRoutes component={Login} />} />
          <Route path="/register/:token?/:id?" element={<PublicRoutes component={Register} />} />
          <Route path="/user-dashboard" element={<ProtectedRoutes component={Userhome} />} />
          <Route path="/arena" element={<ProtectedRoutes component={UserDashboard} />} />
          <Route path="/user-profile" element={<ProtectedRoutes component={Userprofile} />} />
          <Route path="/user-transaction" element={<ProtectedRoutes component={Usertransaction} />} />
          <Route path="/user-deposit" element={<ProtectedRoutes component={UserDeposit} />} />
          <Route path="/user-withdrawal" element={<ProtectedRoutes component={UserWithdrawal} />} />
          <Route path="/bet-history" element={<ProtectedRoutes component={BetHistory} />} />
          <Route path="/game-history" element={<ProtectedRoutes component={GameHistory} />} />
          <Route path="/forgot-password" element={<PublicRoutes component={ForgotPassword} />} />
          <Route path="/reset-password/:token" element={<PublicRoutes component={UpdatePassword} />} />
          <Route path="/test" element={<PublicRoutes component={TestApi} />} />
          <Route path="*" element={<NotFound />} />

          {/* Agent Routes */}
          <Route path="/agent-dashboard" element={<ProtectedRoutesAgent component={AgentDashboard} />} />
          {/* <Route path="/agent-dashboard" element={<ProtectedRoutesAgent component={AgentDashboard} />} /> */}
          <Route path="/agent/player" element={<ProtectedRoutesAgent component={AgentPlayer} />} />
          <Route path="/agent/wallet/:source?/:username?" element={<ProtectedRoutesAgent component={AgentWallet} />} />
          <Route path="/agent/logs" element={<ProtectedRoutesAgent component={AgentLogs} />} />


          {/* Admin Routes */}
          <Route path="/admin/UserList" element={<ProtectedRoutesAdmin component={UserList} />} />
          <Route path="/admin-dashboard" element={<ProtectedRoutesAdmin component={AdminDashboard} />} />
          <Route path="/admin/users" element={<ProtectedRoutesAdmin component={Users} />} />
          <Route path="/admin/bets" element={<ProtectedRoutesAdmin component={Bets} />} />
          <Route path="/admin/bettels" element={<ProtectedRoutesAdmin component={Bettels} />} />
          <Route path="/admin/user/:id" element={<ProtectedRoutesAdmin component={UserDetails} />} />
          <Route path="/admin/bettel/:id" element={<ProtectedRoutesAdmin component={SingleBattel} />} />
          <Route path="/admin/user/add" element={<ProtectedRoutesAdmin component={AddNewUser} />} />


        </Routes>
      </BrowserRouter>
    </>
  )
}
export default Router;