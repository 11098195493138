import React from "react";
import LoginImage from "images/Login.png";


const AgentLogs = () => {

  return (
    <>
      <section className="agentPlayer_dashboard active-bg">
        <div className="container-fluid">
          <div className="row">
            <h1 className="active-caption">Logs</h1>
            <div className="col-md-12">
              <div className="table_wrapper">
                <div className="table_background">
                  <table>
                    <tbody>  
                      <tr>
                        <th>Index</th>
                        <th>Type</th>
                        <th>Customer</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Payment Mode</th>
                        <th>Status</th>
                      </tr>
                      <tr>
                          <td data-cell="index">#1220462</td>
                          <td data-cell="type" class="admin_deposit-green">Deposit</td>
                          <td data-cell="customer">Matte Dison</td>
                          <td data-cell="date">13/05/2022</td>
                          <td data-cell="amount">$1454.95</td>
                          <td data-cell="payment-mode">Bank Transfer</td>
                          <td data-cell="status" class="bold-text">Delivered
                          </td>
                      </tr>
                      <tr>
                          <td data-cell="index">#1220462</td>
                          <td data-cell="type" class="admin_deposit-green">Deposit</td>
                          <td data-cell="customer">Matte Dison</td>
                          <td data-cell="date">13/05/2022</td>
                          <td data-cell="amount">$1454.95</td>
                          <td data-cell="payment-mode">Bank Transfer</td>
                          <td data-cell="status" class="bold-text">Delivered
                          </td>
                      </tr>
                      <tr>
                          <td data-cell="index">#1220462</td>
                          <td data-cell="type" class="admin_deposit-green">Deposit</td>
                          <td data-cell="customer">Matte Dison</td>
                          <td data-cell="date">13/05/2022</td>
                          <td data-cell="amount">$1454.95</td>
                          <td data-cell="payment-mode">Bank Transfer</td>
                          <td data-cell="status" class="bold-text">Delivered
                          </td>
                      </tr>
                      <tr>
                          <td data-cell="index">#1220462</td>
                          <td data-cell="type" class="admin_deposit-green">Deposit</td>
                          <td data-cell="customer">Matte Dison</td>
                          <td data-cell="date">13/05/2022</td>
                          <td data-cell="amount">$1454.95</td>
                          <td data-cell="payment-mode">Bank Transfer</td>
                          <td data-cell="status" class="bold-text">Delivered
                          </td>
                      </tr>
                      <tr>
                          <td data-cell="index">#1220462</td>
                          <td data-cell="type" class="admin_deposit-green">Deposit</td>
                          <td data-cell="customer">Matte Dison</td>
                          <td data-cell="date">13/05/2022</td>
                          <td data-cell="amount">$1454.95</td>
                          <td data-cell="payment-mode">Bank Transfer</td>
                          <td data-cell="status" class="bold-text">Delivered
                          </td>
                      </tr>
                      <tr>
                          <td data-cell="index">#1220462</td>
                          <td data-cell="type" class="admin_deposit-green">Deposit</td>
                          <td data-cell="customer">Matte Dison</td>
                          <td data-cell="date">13/05/2022</td>
                          <td data-cell="amount">$1454.95</td>
                          <td data-cell="payment-mode">Bank Transfer</td>
                          <td data-cell="status" class="bold-text">Delivered
                          </td>
                      </tr>
                      <tr>
                          <td data-cell="index">#1220462</td>
                          <td data-cell="type" class="admin_deposit-green">Deposit</td>
                          <td data-cell="customer">Matte Dison</td>
                          <td data-cell="date">13/05/2022</td>
                          <td data-cell="amount">$1454.95</td>
                          <td data-cell="payment-mode">Bank Transfer</td>
                          <td data-cell="status" class="bold-text">Delivered
                          </td>
                      </tr>
                      <tr>
                          <td data-cell="index">#1220462</td>
                          <td data-cell="type" class="admin_deposit-green">Deposit</td>
                          <td data-cell="customer">Matte Dison</td>
                          <td data-cell="date">13/05/2022</td>
                          <td data-cell="amount">$1454.95</td>
                          <td data-cell="payment-mode">Bank Transfer</td>
                          <td data-cell="status" class="bold-text">Delivered
                          </td>
                      </tr>
                      <tr>
                          <td data-cell="index">#1220462</td>
                          <td data-cell="type" class="admin_deposit-green">Deposit</td>
                          <td data-cell="customer">Matte Dison</td>
                          <td data-cell="date">13/05/2022</td>
                          <td data-cell="amount">$1454.95</td>
                          <td data-cell="payment-mode">Bank Transfer</td>
                          <td data-cell="status" class="bold-text">Delivered
                          </td>
                      </tr>
                      <tr>
                          <td data-cell="index">#1220462</td>
                          <td data-cell="type" class="admin_deposit-green">Deposit</td>
                          <td data-cell="customer">Matte Dison</td>
                          <td data-cell="date">13/05/2022</td>
                          <td data-cell="amount">$1454.95</td>
                          <td data-cell="payment-mode">Bank Transfer</td>
                          <td data-cell="status" class="bold-text">Delivered
                          </td>
                      </tr>
                      <tr>
                          <td data-cell="index">#1220462</td>
                          <td data-cell="type" class="admin_deposit-green">Deposit</td>
                          <td data-cell="customer">Matte Dison</td>
                          <td data-cell="date">13/05/2022</td>
                          <td data-cell="amount">$1454.95</td>
                          <td data-cell="payment-mode">Bank Transfer</td>
                          <td data-cell="status" class="bold-text">Delivered
                          </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AgentLogs  ;