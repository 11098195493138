import React from "react";

const PublicRoutes = ({ component: Component, ...rest }) => {
  return (
    <>
      <Component {...rest} />
    </>
  )
}

export default PublicRoutes;