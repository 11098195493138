import React, { useEffect, useState } from "react";
import LoginImage from "images/Login.png";
import Murga from "images/Murga.png";
import { useDispatch, useSelector } from "react-redux";
import { getLogin } from "redux/Actions/Authentication";
import { setIsLoggedin } from "redux/Reducers/LoggedSlice";
import { setInitialState } from "redux/Reducers/AuthenticationSlice";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
const SECRET_NAME = process.env.REACT_APP_SECRET_NAME;

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { isSuccess, isLoginError, error, userData } = useSelector(state => state.authentication);
  const loggedinUser = useSelector(state => state.loggedin);
  // console.log('loggedinUser',loggedinUser)
  const sha512 = (str) => {
    return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
      return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
    });
  }

  useEffect(() => {
    dispatch(setInitialState());
  }, [])
  const secretName = SECRET_NAME;
  const [hash, setHash] = useState(false);
  const [act, setACT] = useState(false);

  const handleOnchange = (value) => {
    sha512(`${value}:0:${secretName}`).then(x => setHash(x));
  }
  const { isLoggedin } = useSelector(state => state.loggedin);
  // console.log('loggedinUser', loggedinUser);
  useEffect(() => {

    if (isLoggedin) {
      if (Object.keys(loggedinUser.userData).length > 0 &&
        Object.keys(loggedinUser.userData.data).length > 0 &&
        Object.keys(loggedinUser.userData.data.user).length > 0 &&
        loggedinUser.userData.data.user.role_id === 1) {
        navigate("/admin-dashboard");
      } else if (
        Object.keys(loggedinUser.userData).length > 0 &&
        Object.keys(loggedinUser.userData.data).length > 0 &&
        Object.keys(loggedinUser.userData.data.user).length > 0 &&
        (loggedinUser.userData.data.user.role_id === 5
          || loggedinUser.userData.data.user.role_id === 6)
      ) {
        navigate("/agent-dashboard");
      } else if (
        Object.keys(loggedinUser.userData).length > 0 &&
        Object.keys(loggedinUser.userData.data).length > 0 &&
        Object.keys(loggedinUser.userData.data.user).length > 0 &&
        loggedinUser.userData.data.user.role_id === 3
      ) {
        navigate("/user-dashboard");
      }
    }
  }, [])
  useEffect(() => {
    // console.log('userData',userData);
    if (isSuccess || isLoggedin) {
      if (Object.keys(userData).length > 0
        && Object.keys(userData.data).length > 0
        && Object.keys(userData.data.user).length > 0
        && userData.data.user.status !== 'Activated') {
        setACT(userData.data.user.status);
        return false;
      }
      dispatch(setIsLoggedin(userData));
      // console.log('userData', userData)
      if (Object.keys(userData).length > 0 &&
        Object.keys(userData.data).length > 0 &&
        Object.keys(userData.data.user).length > 0 &&
        userData.data.user.role_id === 1) {
        navigate("/admin-dashboard");
      } else if (
        Object.keys(userData).length > 0 &&
        Object.keys(userData.data).length > 0 &&
        Object.keys(userData.data.user).length > 0 &&
        (userData.data.user.role_id === 5
          || userData.data.user.role_id === 6)
      ) {
        navigate("/agent-dashboard");
      } else if (
        Object.keys(userData).length > 0 &&
        Object.keys(userData.data).length > 0 &&
        Object.keys(userData.data.user).length > 0 &&
        userData.data.user.role_id === 3
      ) {
        navigate("/user-dashboard");
      }

    }
  }, [isSuccess])

  const generateRandomNDigits = (n) => {
    return Math.floor(Math.random() * (9 * (Math.pow(10, n)))) + (Math.pow(10, n));
  }

  const onSubmit = (data) => {
    setACT(false);
    const loginapi = {
      foreign_id: data.username,
      secret_name: secretName,
      wallet: 0,
      role_id: 3,
      hashed: hash
    }

    const agentapi = {
      username: data.username,
      password: data.password,
      source: 'web',
      uuid: `ABC${generateRandomNDigits(5)}`,
    }

    const final = {
      login: data,
      loginapi: loginapi,
      agentapi: agentapi
    }

    // dispatch(setLogin());
    dispatch(getLogin(final));
  }


  return (
    <>
      <section className="background-login" style={{ backgroundImage: `url("${LoginImage}")` }}>
        <div className="login_wrapper">
          <div className="logo_wrapper">
            <div className="logo_image"><img src={Murga} className="logo" alt="logo" /> </div>
          </div>
        </div>
        <div className="login_form_header">
          <h2>Play and Win</h2>
        </div>
        <div className="login_form_wrapper">
          <form onSubmit={handleSubmit(onSubmit)}>
            {isLoginError && error &&
              <span className="error d-block" >{error.data}</span>
            }
            {act &&
              <span className="error d-block"> Your account is {act} please contact with agent.  </span>
            }
            <div className="input-container">
              <span className="register_form_icon1 icon_person">  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" className="bi bi-person-fill" color="#3498db" fill="currentColor" viewBox="0 0 16 16">
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
              </svg></span>
              <input type="text" placeholder="User Name"
                {...register("username", {
                  onChange: (e) => { handleOnchange(e.target.value); dispatch(setInitialState()); },
                  required: "Username should not be empty",
                })} />
            </div>
            {errors.username && <span className="error d-block">{errors.username.message}</span>}

            <div className="input-container">
              <span className="register_form_icon"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" color="#3498db" viewBox="0 0 16 16">
                <path d="M3.5 11.5a3.5 3.5 0 1 1 3.163-5H14L15.5 8 14 9.5l-1-1-1 1-1-1-1 1-1-1-1 1H6.663a3.5 3.5 0 0 1-3.163 2zM2.5 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
              </svg></span>
              <input type="password" placeholder="password"
                {...register("password", {
                  onChange: () => { dispatch(setInitialState()); },
                  required: "Password should not be empty",
                })} />
            </div>
            {errors.password && <span className="error d-block">{errors.password.message}</span>}
            <div>
              <Link to="/forgot-password" className="forgot-pswd"><p>Forget Your Password?</p></Link>
            </div>

            <div className="text-center">
              <button type="submit" className="pushable"> <span className="front"> LOGIN </span> </button>
            </div>
          </form>
          <div className="login_bootom">
            <p className="form-bottom-text"> Not Yet Registered? </p>
            <Link to={"/register"} >
              <button className="pushable"> <span className="front"> Register </span> </button>
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login;