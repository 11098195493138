import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAgentUserList,
  userChangeStatus,
  getAgentUserListMurga
} from "redux/Actions/AgentUserList";
import { setInitialState } from "redux/Reducers/AgentUserListSlice";
import { Link } from "react-router-dom";
import Pagination from "components/Pagination";
import { ToastContainer, toast, Zoom } from 'react-toastify';

const AgentPlayers = () => {

  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.loggedin);
  const {
    AgentUserListData,
    agentListMurga,
    isSuccess, error } = useSelector(state => state.AgentUserListData);
  const [currentPage, setCurrentPage] = useState(1);
  const [agent, setAgentList] = useState([]);
  const [search, setSearch] = useState('');
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('');

  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    if (Object.keys(agentListMurga).length > 0
      && Object.keys(agentListMurga.data).length > 0
      ) {
        setAgentList([...agentListMurga.data])
    }
  }, [agentListMurga])
  // console.log('agent',agent);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        toast.success(`Status changed successfully`, {
          toastId: 'success',
          transition: Zoom
        });
        dispatch(setInitialState());
      }, 2000);
    }
    if (Object.keys(error).length > 0
      && typeof error.msg !== 'undefined') {
      setTimeout(() => {
        toast.error(error.msg, {
          toastId: 'success',
          transition: Zoom
        });
        dispatch(setInitialState());
      }, 2000);
    }
    let QST = `${search !== '' ? `&search=${search}` : ''}
    ${role !== '' ? `&${role}` : ''}
    ${status !== '' ? `&status=${status}` : ''}`;
    dispatch(getAgentUserList({
      page: currentPage,
      per_page: 10,
      QST: QST
    }));
  }, [isSuccess, error])

  useEffect(() => {
    dispatch(getAgentUserList({
      page: 2,
      per_page: 10,
      QST: ''
    }));
  }, [userData])

  useEffect(() => {
    let useName = [];
    if (Object.keys(AgentUserListData).length > 0
      && Object.keys(AgentUserListData.pagination).length
      && AgentUserListData.pagination.current_page === 1
    ) {
      AgentUserListData.data.map((item, index) => {
        useName.push(item.username);
      });
      let nuseName = useName.map(x => "'" + x.replace('-3', '') + "'").toString();

      dispatch(getAgentUserListMurga({ username: nuseName }));
      setTotal(AgentUserListData.pagination.total);
      setPerPage(AgentUserListData.pagination.per_page);
    }
  }, [AgentUserListData]);

  useEffect(() => {
    let QST = `${search !== '' ? `&search=${search}` : ''}
    ${role !== '' ? `&role=${role}` : ''}
    ${status !== '' ? `&status=${status}` : ''}`;
    dispatch(getAgentUserList({
      page: currentPage,
      per_page: 10,
      QST: QST
    }));
  }, [currentPage]);

  const handleOnchange = (e, id) => {
    dispatch(userChangeStatus({
      id: id,
      status: e.target.value
    }));
  }

  const handleFilter = () => {
    let QST = `${search !== '' ? `&search=${search}`
      : ''}${role !== '' ? `&role=${role}`
        : ''}${status !== '' ? `&status=${status}`
          : ''}`;
    dispatch(getAgentUserList({
      page: 1,
      per_page: 10,
      QST: QST
    }));
  }

  
  return (
    <>
      <ToastContainer transition={Zoom} position={toast.POSITION.TOP_CENTER} />
      <section className="agentPlayer_dashboard active-bg">
        <div className="container-fluid">
          <div className="row">
            <h1 className="active-caption">Player Management</h1>
            <div className="col-md-12">
              <div className="filtter_wrapper">
                <div className="row">
                  <div className="col-md-4">
                    <input type="text" className="search"
                      placeholder="Username, Email , Name" id="agent_player_search"
                      onChange={(e) => setSearch(e.target.value)} />
                  </div>
                  <div className="col-md-3">
                    <select
                      className="flex-1 px-3 py-2 border dropdown_role_id placeholder-gray-400"
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">- Status (All) -</option>
                      <option value="Pending">Pending</option>
                      <option value="Activated">Activated</option>
                      <option value="Suspended">Suspended</option>
                      <option value="Cancelled">Cancelled</option>
                      <option value="Archived">Archived</option>
                      <option value="Deactivated">Deactivated</option>
                    </select>
                  </div>
                  <div className="col-md-3">
                    <select
                      className="flex-1 px-3 py-2 border placeholder-gray-400 dropdown_role_id"
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value="">- Roles (All) -</option>
                      <option value="player">Players</option>
                      <option value="platinum_agent">Platinum Agent</option>
                      <option value="master_agent">Master Agent</option>
                    </select>
                  </div>
                  <div className="col-md-2">
                    <button className="flex-2 px-6 py-2 text-sm text-white apply_filter_button"
                      onClick={() => handleFilter()}
                    >
                      Apply Filters
                    </button>
                  </div>

                </div>
              </div>
              <div className="table_wrapper">
                <div className="table_background">
                  <table>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Ph Number</th>
                        <th>Wallet</th>
                        <th>Com. Wallet</th>
                        <th>Mobile</th>
                        <th>Registered Date</th>
                        <th>Status</th>
                        <th>Role Type</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        typeof AgentUserListData !== 'undefined'
                          && Object.keys(AgentUserListData).length > 0
                          && Object.keys(AgentUserListData.data).length
                          ? AgentUserListData.data.map((item, index) => {
                            let usena = item.username;
                            usena = usena.replace('-3','');
                            const OA = agent.find(item => item.username == usena);
                            return (
                              <tr key={`pl${index}`}>
                                <td data-label="ID" className="gameHistorySerialHide">
                                  {(currentPage * perPage) + index + 1 - perPage}
                                </td>
                                <td data-cell="Username">{OA?.username ? OA?.username : item?.username}</td>
                                <td data-cell="email">{OA?.email}</td>
                                <td data-cell="phone">{OA?.phnumber}</td>
                                <td data-cell="Wallet">{parseFloat(item.wallet).toFixed(2)}</td>
                                <td data-cell="Com. Wallet">{parseFloat(item.commission_wallet).toFixed(2)}</td>
                                <td data-cell="Mobile">{item.phone}</td>
                                <td data-cell="Registered Date">{item.created_at}</td>
                                <td data-cell="Status">
                                  {item.status === 'Pending' ?
                                    <div className="activateaccount">
                                      <select
                                        defaultValue={item.status}
                                        onChange={(e) => handleOnchange(e, item.id)}
                                      >
                                        <option value={`Activated`}>Activated</option>
                                        <option value={`Pending`}>Pending</option>
                                        <option value={`Suspended`}>Suspended</option>
                                        <option value={`Archived`}>Archived</option>
                                        <option value={`Cancelled`}>Cancelled</option>
                                      </select>
                                    </div>
                                    :
                                    item.status
                                  }
                                </td>
                                <td data-cell="Role Type">{item.role}</td>
                                <td data-cell="Actions">
                                  <div className="agentuserlist_action_buttom">
                                    <Link to={`/agent/wallet/Cash-In/${item.username}`} className="custom_button">Cash In</Link>
                                    <Link to={`/agent/wallet/Cash-Out/${item.username}`} className="custom_button">Cash Out</Link>
                                  </div>
                                </td>
                              </tr>
                            )
                          }) :
                          <tr>
                            <td style={{ textAlign: "center" }} colSpan={9} >No Data Found</td>
                          </tr>
                      }
                    </tbody>
                  </table>
                  {(total > perPage) && (
                    <div className="setPagination pb-1 pe-4 pt-3">
                      <Pagination
                        perPage={perPage}
                        total={total}
                        paginate={paginate}
                        currentPage={currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AgentPlayers;