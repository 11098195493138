import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBattleList, getCsvBattle } from "redux/Actions/Admin/Battles";
import { setInitialState } from "redux/Reducers/Admin/BattlesSlice";
import { ToastContainer, toast, Zoom } from 'react-toastify';
import Pagination from "components/Pagination";
import moment from "moment";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

const Bettels = () => {
  const dispatch = useDispatch();
  const { isSuccess,
    csvBattle,
    error,
    battleList } = useSelector(state => state.adminBattle);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState('');
  const [dtFrom, setdtFrom] = useState('');
  const [dtTo, setdtTo] = useState('');
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(12);
  const [headers, setHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);



  useEffect(() => {
    if (Object.keys(csvBattle).length > 0
      && csvBattle.data.length > 0) {
      setCsvData([...csvBattle.data])
      setTimeout(() => {
        genReport.current.link.click();
        dispatch(setInitialState());
      }, 2000);
    }
  }, [csvBattle])

  const genReport = useRef(null);
  const downloadReport = () => {
    let QST = `${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getCsvBattle({
      page: 1,
      per_page: total,
      QST: QST
    }));
  }
  const csvReport = {
    data: csvData,
    headers: headers,
    filename: 'BattleCsv.csv'
  };
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        toast.success(`Status changed successfully`, {
          toastId: 'success',
          transition: Zoom
        });
        dispatch(setInitialState());
      }, 2000);
    }
    if (Object.keys(error).length > 0
      && typeof error.msg !== 'undefined') {
      setTimeout(() => {
        toast.error(error.msg, {
          toastId: 'success',
          transition: Zoom
        });
        dispatch(setInitialState());
      }, 2000);
    }
    let QST = `${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getBattleList({
      page: currentPage,
      per_page: 10,
      QST: QST
    }));
  }, [isSuccess, error])
  useEffect(() => {
    if (Object.keys(battleList).length > 0
      && Object.keys(battleList.pagination).length
      && battleList.pagination.current_page === 1
    ) {
      let thead = [];
      for (const key in battleList.data[0]) {
        thead.push(
          { label: key, key: key },
        )
      }
      setHeaders(thead)
      setTotal(battleList.pagination.total);
      setPerPage(battleList.pagination.per_page);
    }
  }, [battleList]);
  useEffect(() => {
    let QST = `${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getBattleList({
      page: currentPage,
      per_page: 10,
      QST: QST
    }));
  }, [currentPage]);

  useEffect(() => {
    dispatch(getBattleList({
      page: currentPage,
      per_page: 10,
      QST: ""
    }))
  }, []);
  const handleFilter = () => {
    let QST = `${status !== '' ? `&status=${status}` : ''}
    ${dtFrom !== '' ? `&dt_from=${dtFrom}` : ''}
    ${dtTo !== '' ? `&dt_to=${dtTo}` : ''}`;
    dispatch(getBattleList({
      page: 1,
      per_page: 10,
      QST: QST
    }));
  }
  return (
    <>
      <ToastContainer transition={Zoom} position={toast.POSITION.TOP_CENTER} />
      <main className="active-bg" id="bet-histories">
        <div className="container-fluid">
          <div className="row">
            <div className="table-wrapper">
              <div className="table-container">
                <h1 className="active-caption-cash">Bettels Histories</h1>
                <div className="flex search-box-bg justify-between px-4 gap-2 mb-2">
                  <input onChange={(e) => setStatus(e.target.value)} className=" flex-1 px-3 py-2 border text-sm placeholder-gray-400 search-text" placeholder="Search (e.g. Name, Reference, #, etc)" type="text" fdprocessedid="e2mns"></input>
                  <div className="relative w-auto">
                    <div className="date-container">
                      <input type="date" name="date"
                        onChange={(e) => setdtFrom(moment(e.target.value).format('MMMM D, YYYY'))} />
                    </div>
                  </div>

                  <div className="relative w-auto">
                    <input type="date" name="date"
                      onChange={(e) => setdtTo(moment(e.target.value).format('MMMM D, YYYY'))} />
                  </div>

                  <button onClick={() => downloadReport()}
                    className="flex-2 px-6 py-2 text-sm text-white apply_filter_button">
                    Generate Report
                  </button>
                  <CSVLink {...csvReport} ref={genReport} style={{ display: 'none' }}>
                    Generate Report
                  </CSVLink>
                  <button className="flex-2 px-6 py-2 text-sm text-white apply_filter_button"
                    onClick={() => handleFilter()}> Apply Filters </button>
                </div>
                <table>
                  <tbody>
                    <tr>
                      <th>S.No</th>
                      <th>FIGHT #</th>
                      <th>MERON</th>
                      <th>WALA</th>
                      <th>NET PAY</th>
                      <th>OPR COM.</th>
                      <th>Agent COM.</th>
                      <th>STATUS</th>
                      <th>ACTION</th>
                    </tr>
                    {Object.keys(battleList).length > 0 &&
                      battleList.data.length > 0 ?
                      battleList.data.map((item, index) => {
                        return (
                          <tr key={`adb${index}`}>
                            <td data-cell="S.No." data-label="S.no.">{(currentPage * perPage) + index + 1 - perPage}</td>
                            <td data-cell="Fight#" data-label="Fight#">{item?.fight_number}</td>
                            <td data-cell="Meron." data-label="MERON">{item?.m_bet}</td>
                            <td data-cell="wala" data-label="WALA">{item?.w_bet}</td>
                            <td data-cell="Net Pay" data-label="Net Pay">₹{parseFloat(item?.net_pay).toFixed(2)}</td>
                            <td data-cell="OPR com." data-label="OPR com.">₹{parseFloat(item?.super_commission).toFixed(2)}</td>
                            <td data-cell="Agent com." data-label="Agent com.">₹{parseFloat(item?.platinum_commission).toFixed(2)}</td>
                            <td data-cell="Status" data-label="Status">{item?.status}</td>
                            <td data-cell="Action" data-label="Action">
                              <Link to={`/admin/bettel/${item?.id}`}
                                className="btn btn-primary apply_filter_button">
                                Details
                              </Link>
                            </td>
                          </tr>
                        )
                      })
                      :
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={9} >No Data Found</td>
                      </tr>
                    }
                  </tbody>
                </table>
                {(total > perPage) && (
                  <div className="setPagination pb-1 pe-4 pt-3">
                    <Pagination
                      perPage={perPage}
                      total={total}
                      paginate={paginate}
                      currentPage={currentPage}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}

export default Bettels;