import React, { useState } from "react";
import Murga from "images/Murga.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRightFromBracket,
  faHandshake,
  faHome,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import { faUserPen } from '@fortawesome/free-solid-svg-icons';
import { setLogout } from "redux/Reducers/LoggedSlice";

const AgentHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Logout = () => {
    dispatch(setLogout());
    navigate("/");
  }
  const [burger_class, setBurgerClass] = useState("bar")
  const [menu_class, setMenuClass] = useState("nav-menu-hidden")
  const [isMenuClicked, setIsMenuClicked] = useState(false);
  const [backgroundClickContainer, setBackgroundClickContainer] = useState('clickNoneContainer')

  const updateMenu = () => {
    if (!isMenuClicked) {
      setBurgerClass("cross");
      setMenuClass("nav-menu-visible");
      setIsMenuClicked(true);
      setBackgroundClickContainer("clickDisplayContainer");
    }
    else {
      setBurgerClass("bar");
      setMenuClass("nav-menu-hidden");
      setIsMenuClicked(false);
      setBackgroundClickContainer("clickNoneContainer");
    }
  }


  return (
    <>
      <section className="announcement_bar">
        <marquee text="murga"> Agent Your Playing Hours are between 8am to 9pm</marquee>
      </section>
      <div className={menu_class}>
        <Link to="/agent-dashboard">
          <FontAwesomeIcon icon={faHome} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Dashboard
          </span>
        </Link>
        <Link to="/agent/player">
          <FontAwesomeIcon icon={faUserPen} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Player Management
          </span>
        </Link>
        <Link to="/agent/wallet">
          <FontAwesomeIcon icon={faHandshake} />
          <span className="nav-item-discription-text" onClick={updateMenu} >
            Wallet
          </span>
        </Link>
        <Link onClick={() => Logout()} className="logout-container" >
          <FontAwesomeIcon icon={faRightFromBracket} />
        </Link>
      </div>
      <div className="sidebar">
        <div className="murga-logo">
          <Link to="/agent-dashboard"><img src={Murga} className="img-fluid sidebar-logo" alt="logo" />
          </Link>
        </div>
        <Link to="/agent-dashboard" id="user_info_container" ><FontAwesomeIcon icon={faUser} /></Link>
        <div className="hamburger" onClick={updateMenu}>
          <span className={burger_class}></span>
          <span className={burger_class}></span>
          <span className={burger_class}></span>
        </div>
        <div className={backgroundClickContainer} onClick={updateMenu} ></div>
      </div>
    </>
  )
}

export default AgentHeader;