import React, { useEffect, useState } from "react";
import LoginImage from "images/Login.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { transactionCreate } from "redux/Actions/UserTransaction";
import { setInitialState } from "redux/Reducers/UserTransactionSlice"
import { ToastContainer, toast, Zoom } from 'react-toastify';
const SECRET_NAME = process.env.REACT_APP_SECRET_NAME;

const UserDeposit = () => {

  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.loggedin);
  const userid = userData.data.user.id;
  const { isSuccess, error } = useSelector(state => state.userTransactionData);

  useEffect(() => {
    if (isSuccess) {
      toast.success(`${amount} is added in your wallet`, {
        toastId: 'amount',
        transition: Zoom
      });
      dispatch(setInitialState());
    }
    if (error) {
      toast.error(`${error.msg}`, {
        toastId: 'amount',
        transition: Zoom
      });
      dispatch(setInitialState());
    }
  }, [dispatch,isSuccess, error])
  const sha512 = (str) => {
    return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
      return Array.prototype.map.call(new Uint8Array(buf), x => (('00' + x.toString(16)).slice(-2))).join('');
    });
  }
  const secretName = SECRET_NAME;
  const [hash, setHash] = useState(false);

  const walletAmount = [100, 200, 500];
  const [amount, setAmount] = useState(false);
  const handlleAmount = (value) => {
    sha512(`${userid}:${value}:${secretName}`).then(x => setHash(x));
    setAmount(value);
  }
  const onSubmit = () => {
    if (amount && amount > 0) {
      const data = {
        transacted_to_id: userid,
        amount: amount,
        source: 'Cash-In',
        hashed: hash
      }
      dispatch(transactionCreate(data));

    } else {
      toast.error('Amount should be grater then 0.', {
        toastId: 'amount',
        transition: Zoom
      });
    }
  }
  // console.log(userTransactionData);

  return (
    <>
      <ToastContainer transition={Zoom} position={toast.POSITION.TOP_CENTER} autoClose={1000} />
      <section className="dashboard-bg-image" style={{ backgroundImage: `url("${LoginImage}")` }}>
        <div className="container-fluid">
          <div className="section_title">
            <h1 className="transaction-heading text-center">Deposit</h1>
          </div>
          <div className="deposit_wrapper">
            <div className="deposit-blue-bg">
              <div className="deposit_screen_wrapper">
                <div className="desposti_screen_item">
                  <div className="desposti_screen_item_deposit_box">
                    <label>Amount</label>
                    <input type="number" value={amount} onChange={(e) => handlleAmount(e.target.value)} />
                    <input type="submit" onClick={() => onSubmit()}
                      value="Submit" className="btn btn-primary custom-button" />
                  </div>
                  <div className="desposti_screen_item_deposit_predefine_value">
                    {walletAmount.map((item, index) => {
                      return (
                        <div key={index}
                          className={`depsoit_amount_predefined`} onClick={
                            () => handlleAmount(item)}> {item} </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default UserDeposit;